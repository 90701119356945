export class EventTranslate {
  id: number;
  languageCode: string;

  title?: string;
  description?: string;
  eventDateDescription?: string;
  developmentGoal?: string;

  constructor(languageCode) {
    this.languageCode = languageCode;
  }
}
