import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";

@Component({
  selector: 'app-szitas1144-carousel',
  templateUrl: './szitas1144-carousel.component.html',
  styleUrls: ['./szitas1144-carousel.component.scss']
})
export class Szitas1144CarouselComponent implements OnInit {

  images: object[] = [];
  activeSlideIndex: number = 0;
  activeSlide: {};
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  @ViewChild('ngxGalleryRef') ngxGalleryRef: NgxGalleryComponent;

  constructor() {
    this.initCarousel();
    this.initGallery();
  }

  ngOnInit() {

  }

  initCarousel() {
    this.images.push(
      {
        url: '/assets/images/projects/szitas1144/main_cover.jpg'
      }
    );

    this.images.push(
      {
        url: '/assets/images/projects/szitas1144/cover_01.jpg'
      }
    );

    this.images.push(
      {
        url: '/assets/images/projects/szitas1144/cover_02.jpg'
      }
    );

    this.images.push(
      {
        url: '/assets/images/projects/szitas1144/cover_03.jpg'
      }
    );

  }


  onActiveSlideChange(index) {
    this.activeSlideIndex = index;
    this.activeSlide = this.images[this.activeSlideIndex];
  }

  setActiveSlideIndex(index) {
    this.activeSlideIndex = index;
  }

  getBackgroundImageStyle(imageUrl) {
    return {
      'background-image': 'url(' + imageUrl + ')',
    };
  }

  openPreview(): void {
    this.ngxGalleryRef.openPreview(this.activeSlideIndex);
  }

  initGallery() {
    // TODO
    this.galleryOptions = [
      {
        image: false,
        thumbnails: false,
        width: "0px",
        height: "0px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowPrevIcon: "icon angle left",
        arrowNextIcon: "icon angle right",
        previewSwipe: true,
        thumbnailsSwipe: true
      }
    ];

    this.galleryImages = [];
    for (let galleryIndex = 0; galleryIndex < this.images.length; galleryIndex++) {

      let galleryImageItem = {
        small: this.images[galleryIndex]['url'],
        medium: this.images[galleryIndex]['url'],
        big: this.images[galleryIndex]['url']
      };
      this.galleryImages.push(galleryImageItem);
    }

  }


}
