import {Nuts3} from "./nuts3.model";

export class Partner {
  id?: string;
  name?: string;
  abbreviatedName?: string;
  webSite?: string;
  officialAddressState?: string;
  officialAddressCounty?: string;
  officialAddressTown?: string;
  officialAddressPostalCode?: string;
  officialAddressStreetAndNumber?: string;
  officialAddressPoBox?: string;
  mailingAddressState?: string;
  mailingAddressCounty?: string;
  mailingAddressTown?: string;
  mailingAddressPostalCode?: string;
  mailingAddressStreetAndNumber?: string;
  mailingAddressPoBox?: string;
  nuts3List: Nuts3[];
  email: string;
  phone: string;
  secondaryPhone: string;
}
