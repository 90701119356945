import {Component, OnInit} from '@angular/core';
import {Flat} from "../../shared/models/building/flat.model";
import {Router} from "@angular/router";
import {FlatService} from "../../shared/services/flat.service";
import * as _ from 'underscore';
import 'rxjs';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-flats',
  templateUrl: './flats.component.html',
  styleUrls: ['./flats.component.scss']
})
export class FlatsComponent implements OnInit {

  sortOptions: object[] = [];
  selectedSortOption: {} = {};

  levelOptions: object[] = [];
  selectedLevelOption: {} = {};

  projectOptions: object[] = [];
  selectedProjectOption: {} = {};

  maxPrice: number = 99000000;
  minSize: number = 30;
  minNumberOfRooms: number = 1;

  flats: Flat[] = [];
  imagesList: object[] = [];

  constructor(private router: Router, private flatService: FlatService, private httpClient: HttpClient) {

    this.initProjectOptions();
    this.initSortOptions();
    this.initLevelOptions();

    this.selectedSortOption = this.sortOptions[0];
    this.selectedLevelOption = this.levelOptions[5];
    this.selectedProjectOption = this.projectOptions[0];

    this.flatService.flats.subscribe((data) => {
      this.flats = data;

      for (let i = 0; i < this.flats.length; i++) {
        this.flats[i]['url'] = '/assets/images/projects/' + this.flats[i].project.toLowerCase() + '/flats/small/' + this.flats[i].id + '_P.jpg';
        this.flats[i]['show'] = false;
      }


    })


  }

  ngOnInit() {
  }


  getFlatList() {

    if (this.selectedSortOption['reversed']) {
      return _.sortBy(this.flats, this.selectedSortOption['key']).reverse();
    }

    const sortedFlats = _.sortBy(this.flats, this.selectedSortOption['key']);
    const unSoldFlats = sortedFlats.filter(item=>!item.soldOut && !item.reserved)
    const soldFlats = sortedFlats.filter(item=>item.soldOut || item.reserved)
    return [...unSoldFlats, ...soldFlats];
  }


  showFlat(flat) {

    if (flat.level !== this.selectedLevelOption['value'] && this.selectedLevelOption['value'] !== 'all') {
      return false;
    }

    if (flat.price > this.maxPrice) {
      return false;
    }

    if(flat.size < this.minSize){
      return false;
    }

    if(flat.numberOfRooms < this.minNumberOfRooms){
      return false;
    }

    if(flat.project !== this.selectedProjectOption['value'] ){
      return false;
    }

    return true;

  }

  initSortOptions() {
    this.sortOptions = [
      {
        name: "lakás számok szerint növekvő",
        key: "name"
      },
      {
        name: "lakás számok szerint csökkenő",
        key: "name",
        reversed: true
      },
      {
        name: "méret szerint növekvő",
        key: "size"
      },
      {
        name: "méret szerint csökkenő",
        key: "size",
        reversed: true
      },
      {
        name: "ár szerint növekvő",
        key: "price"
      },
      {
        name: "ár szerint csökkenő",
        key: "price",
        reversed: true
      },
    ];
  }

  initLevelOptions() {
    this.levelOptions = [
      {
        name: "Földszint",
        value: 0
      },
      {
        name: "1. emelet",
        value: 1
      },
      {
        name: "2. emelet",
        value: 2
      },
      {
        name: "3. emelet",
        value: 3
      },
      {
        name: "4. emelet",
        value: 4
      },
      {
        name: "Összes",
        value: "all"
      },
    ];
  }

  initProjectOptions() {
    this.projectOptions = [
      {
        name: "Horizont Lakópark",
        value: 'HORIZONT'
      },
    ];
  }

  removeHuf(priceString) {
    return priceString.replace("HUF", "");
  }





}
