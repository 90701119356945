import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {User} from "../../models/user.model";
import {StateService} from "../../services/state.service";


@Component({
  selector: 'app-custom-side-menu',
  templateUrl: './custom-side-menu.component.html',
  styleUrls: ['./custom-side-menu.component.scss']
})
export class CustomSideMenuComponent implements OnInit {

  currentUser: User;
  isSidebarOpen: Boolean;
  logoutTitle: string;
  logoutQuestion: string;

  constructor(private router: Router,
              private translate: TranslateService,
              private stateService: StateService) {
    this.getTranslations();
    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    })
  }

  ngOnInit() {


  }


  getTranslations() {
    this.translate.get('MENU.LOG_OUT').subscribe((res: string) => {
      this.logoutTitle = res;
    });
    this.translate.get('QUESTIONS.LOG_OUT').subscribe((res: string) => {
      this.logoutQuestion = res;
    });
  };


  showLogoutModal() {

  }


  toggleSideBar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.stateService.setSelectedSideMenuState(this.isSidebarOpen);
  }

  isUserLoggedIn() {

  }


  openPage(pageCode) {
    this.toggleSideBar();
    this.router.navigateByUrl('/home/page/' + pageCode);
  }

  openPages(pageCode, subPageCode) {
    this.toggleSideBar();
    this.router.navigate(['/home/pages/' + pageCode], {queryParams: {page: subPageCode}});
  }


  openArticleList(topic) {
    this.toggleSideBar();
    if(topic){
      this.router.navigate(['/home/news'], {queryParams: {topic: topic}});
    }else{
      this.router.navigateByUrl('/home/news');
    }
  }

  openArticlesPage(pageType) {
    // this.router.navigate(['/home/articles'], {queryParams: {pageType: this.pageType}});
    this.router.navigateByUrl('/home/articles/' + pageType.toLowerCase());
  }



}
