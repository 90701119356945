import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-brand-image',
  templateUrl: './brand-image.component.html',
  styleUrls: ['./brand-image.component.scss']
})
export class BrandImageComponent implements OnInit {

  @Input() isWhite: boolean;

  constructor() { }

  ngOnInit() {
  }

}
