import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pdf-viewer-wrapper',
  templateUrl: './pdf-viewer-wrapper.component.html',
  styleUrls: ['./pdf-viewer-wrapper.component.scss']
})
export class PdfViewerWrapperComponent implements OnInit {

  @Input() pdfSrc: string;
  @Input() backToTitle: string;
  @Output() viewerClosed = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  closePdfViewer() {
    this.viewerClosed.emit();
  }


}
