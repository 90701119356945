import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {FlatService} from "../../../shared/services/flat.service";
import {Location} from "@angular/common";
import {Router} from '@angular/router';

@Component({
  selector: 'app-horizont-page',
  templateUrl: './horizont-page.component.html',
  styleUrls: ['./horizont-page.component.scss']
})
export class HorizontPageComponent implements OnInit {

  youtubeVideoUrl: SafeResourceUrl;
  shareUrl = 'http://horizontlakopark.hu/share/project.php?id=horizont';

  advantageStates: object[] = [
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    },
    {
      isOpen: false,
    }
  ];
  pageLoaded: boolean;

  constructor(private sanitizer: DomSanitizer,
              private location: Location,
              private router: Router,
              private flatService: FlatService) {

    this.youtubeVideoUrl = this.transformVideoUrl("https://youtu.be/-4IvIl8At14");
    this.initFacebookApp();
  }

  ngOnInit() {

    setTimeout(()=>{
      this.pageLoaded = true;
    }, 1000);
  }

  setAdvantageState(index) {
    this.advantageStates[index]["isOpen"] = !this.advantageStates[index]["isOpen"];
  }

  isAdvantageOpen(index) {
    return this.advantageStates[index]["isOpen"];
  }



  transformVideoUrl(videoUrl) {

    let videoId = this.getYoutubeVideoId(videoUrl);
    if (videoId != null || videoId !== 'error') {
      videoUrl = 'https://www.youtube.com/embed/' + videoId;
    } else {
      videoUrl = null;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);

  }

  getYoutubeVideoId(url) {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  initFacebookApp(){
    if (!window['fbAsyncInit']) {
      window['fbAsyncInit'] = function () {
        window['FB'].init({
          autoLogAppEvents: true,
          xfbml: true,
          appId: '170555663821545',
          version: 'v3.0'
        });
      };
    }

    // load facebook sdk if required
    const url = 'https://connect.facebook.net/en_US/sdk.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    }
  }

  openGalleryPage(){
    this.router.navigateByUrl('/home/gallery/horizont');
  }

  shareOnFacebook() {
    window['FB'].ui({
      method: 'share',
      href: this.shareUrl,
    }, (response) => {

    });
  }


  backToPrevPage() {
    this.location.back();
  }


}
