import {EventTranslate} from "./event-translate.model";
import {Partner} from "./partner.model";
import {Nuts3} from './nuts3.model';
import {Page} from "./page.model";
import {Tag} from "./tag.model";

export class Event {

  eventType?: string;
  eventEndDate?: string;
  eventStartDate?: string;
  eventTrls?: EventTranslate[] = [];
  id?: number;
  imageUrl?: string;
  pdfUrl?: string;
  latitude?: number;
  longitude?: number;

  address?: string;
  helpdesk?: string;
  eventStatus?: string;
  maxNumberOfParticipants?: number;

  global?: boolean;
  active?: boolean;
  highlighted?: boolean;
  isFavorite?: boolean;

  translatedDescription?: string;
  translatedLocation?: string;
  translatedTitle?: string;

  nuts3?: Nuts3;
  partner?: Partner;
  languageCode?: string;

  startDateOfApplication?: string;
  endDateOfApplication?: string;

  menuPage?: Page;
  articleEventId?: number;
  eventEventId?: number;
  assigned?: boolean;
  tags?: Tag[];
}
