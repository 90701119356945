import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {
  CRS,
  imageOverlay,
  latLng,
  LatLng,
  latLngBounds,
  LatLngBounds,
  map,
  Map,
  Point,
  tileLayer,
  marker,
  Marker,
  icon
} from 'leaflet';
import * as _ from 'underscore';
import {Flat} from "../../models/building/flat.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.scss']
})
export class ImageMapComponent implements OnInit, OnChanges {

  @Input() mapImageUrl;
  @Input() flats: Flat[];
  @Input() selectedFlat: Flat[];
  @Input() parentContainerClass;

  @Input() moveSelectedFlat: boolean;

  @Output() flatSelected = new EventEmitter();
  @Output() flatMoved = new EventEmitter();

  @ViewChild('mapImageContainer') mapImageContainerElement: ElementRef;
  @ViewChild('mapImage') mapImageElement: ElementRef;

  ready = false;

  map: Map;
  markers: Marker[] = [];

  imageNaturalHeight: number;
  imageNaturalWidth: number;

  panelHeight: number;
  panelWidth: number;

  panelBounds: LatLngBounds;

  mapImageOverlay;
  mapOptions = {
    layers: [],
    crs: CRS.Simple,
    attributionControl: false
  };

  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedFlat) {
      this.selectedFlat = changes.selectedFlat.currentValue;
    }

    if (changes && changes.flats) {
      this.flats = changes.flats.currentValue;
    }

    if (changes && changes.parentContainerClass) {
      this.parentContainerClass = changes.parentContainerClass.currentValue;
    }

    if (changes && (changes.selectedFlat || changes.flats)) {
      if (this.map) {
        this.refreshMarkers();
      }
    }

  }

  ngOnInit() {


  }

  onLoadedImage(event) {

    event.target.hidden = true;

    this.imageNaturalHeight = event.target.height;
    this.imageNaturalWidth = event.target.width;

    if (this.parentContainerClass) {
      const parentContainer = document.getElementsByClassName(this.parentContainerClass)[0];
      this.panelHeight = parentContainer.clientHeight;
      this.panelWidth = parentContainer.clientWidth;
    } else {
      this.panelHeight = this.mapImageContainerElement.nativeElement.offsetParent.offsetHeight;
      this.panelWidth = this.mapImageContainerElement.nativeElement.offsetParent.offsetWidth;
    }

    this.panelBounds = latLngBounds(latLng(0, 0),
      latLng(this.panelHeight, (this.panelHeight * this.imageNaturalWidth) / this.imageNaturalHeight));

    this.mapImageElement.nativeElement.style.width = this.panelWidth + 'px';
    this.mapImageElement.nativeElement.style.height = this.panelHeight + 'px';

    this.mapImageOverlay = imageOverlay(this.mapImageUrl, this.panelBounds, {crossOrigin: true});

    this.ready = true;

    if (this.map) {
      // const maxZoom = this.map.getBoundsZoom(this.maxBounds, false);
      // const minZoom = this.map.getBoundsZoom(bounds, false);
      // console.log(minZoom, maxZoom);

      this.map.addLayer(this.mapImageOverlay);
      this.map.fitBounds(this.panelBounds);
      this.map.setMaxBounds(this.panelBounds);
      this.refreshMarkers();
    }

  }

  onMapReady(map: Map) {

    this.map = map;

    // if (this.moveSelectedFlat) {
    //   map.on('click', e => {
    //     this.moveSelectedFlatTo(e.latlng);
    //   });
    // }

    if (this.mapImageOverlay) {
      this.map.addLayer(this.mapImageOverlay);
      this.map.fitBounds(this.panelBounds);
      this.map.setMaxBounds(this.panelBounds);
      this.refreshMarkers();
    }

  }

  // moveSelectedFlatTo(latLng: LatLng) {
  //   if (this.selectedFlat) {
  //     const marker = this.getMarkerById(this.selectedFlat.id);
  //     if (marker) {
  //       marker.removeFrom(this.map);
  //     }
  //
  //     this.markers = _.without(this.markers, marker);
  //
  //     const imageLatLng = this.relPosToImage(latLng.lat, latLng.lng);
  //
  //     this.selectedFlat.longitude = imageLatLng.lat;
  //     this.selectedFlat.latitude = imageLatLng.lng;
  //
  //     this.addMarker(this.selectedFlat);
  //
  //     this.flatMoved.emit(this.selectedFlat);
  //   }
  // }

  refreshMarkers() {
    _.forEach(this.markers, marker => {
      marker.removeFrom(this.map);
    });

    _.forEach(this.flats, flat => {
      this.addMarker(flat);
    });
  }

  addMarker(flat) {

    let iconSize = [48, 48];
    let iconAnchor = [24, 48];

    const loc = this.relPosToLatLng(flat.longitude, flat.latitude);
    let iconUrl = '/assets/images/map/flat_marker.png';

    if (this.selectedFlat && this.selectedFlat['id'] === flat.id) {
      iconUrl = '/assets/images/map/flat_marker_selected_2.png';
      iconSize = [96, 96];
      iconAnchor = [48, 96];
    }

    if (flat['soldOut']) {
      iconUrl = '/assets/images/map/sold_out.png';
      iconSize = [80, 80];
      iconAnchor = [40, 40];
    }


    const mapMarker = marker(loc, {
      icon: icon({
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        iconUrl: iconUrl
      }),
      flat: flat
    });

    if (flat.name) {
      const url = location.href;
      let split = url.split('/#/');

      if(flat['soldOut']){
        const note = '<div class="flat-link-sold-out" href="' + split[0] + '/#/home/flat/' + flat.project + '/' + flat.id + '"><b>' +
            'ELADVA'
        '</a>';
        mapMarker.addTo(this.map).bindPopup(note);
        mapMarker.bindTooltip(note);
      }else{
        if (this.selectedFlat && this.selectedFlat['id'] === flat.id) {
          const note = '<div class="flat-link">' +
            flat.name + ' lakás' + '<br></b>' +
            flat.size + ' m²' + '<br></b>' + 'Jelenleg ezt a lakást nézi!'
          '</div>';
          mapMarker.addTo(this.map).bindPopup(note);
          mapMarker.bindTooltip(note);

        } else {
          const note = '<div class="flat-link" href="' + split[0] + '/#/home/flat/' + flat.project + '/' + flat.id + '"><b>' +
            flat.name + ' lakás' + '<br></b>' +
            flat.size + ' m²' + '<br></b>' + 'Kattintson a részletekért!'
          '</a>';

          mapMarker.addTo(this.map).bindPopup(note);
          mapMarker.bindTooltip(note);
        }
      }


    } else {
      mapMarker.addTo(this.map);
    }

    mapMarker.on('click', e => {

      this.router.navigateByUrl('/home/flat/' + flat.project + "/" + flat.id);
      // console.log("click", e.target);

      // this.selectMarker(e.target);
    });



    this.markers.push(mapMarker);
    this.map.invalidateSize();
  }

  getMarkerById(id) {
    return _.find(this.markers, marker => {
      return marker.options.flat.id === id;
    });
  }

  selectMarker(marker) {
    this.flatSelected.emit(marker.options.flat);
  }

  /*
 * Convert relative image coordinate to Leaflet LatLng point
 */
  relPosToLatLng(lat, lng): LatLng {
    if (this.ready) {
      return latLng(
        this.panelBounds.getNorthEast().lat - (lat * this.panelBounds.getNorthEast().lat / this.imageNaturalHeight)
        , lng * this.panelBounds.getNorthEast().lng / this.imageNaturalWidth);
    } else {
      return null;
    }
  }

  /*
 * Convert relative image coordinate to Image pixel
 */
  relPosToImage(lat, lng): LatLng {
    if (this.ready) {
      return latLng(
        (this.panelBounds.getNorthEast().lat - lat) * this.imageNaturalHeight / this.panelBounds.getNorthEast().lat
        , lng * this.imageNaturalWidth / this.panelBounds.getNorthEast().lng);
    } else {
      return null;
    }
  }

}
