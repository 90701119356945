import {ModuleWithProviders, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SidebarModule} from 'ng-sidebar';
import {
  SuiDatepickerModule,
  SuiDimmerModule,
  SuiDropdownModule,
  SuiModalModule,
  SuiPopupModule,
  SuiSearchModule,
  SuiSelectModule,
  SuiSidebarModule,
  SuiTabsModule
} from 'ng2-semantic-ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';

import {SharedModule} from './shared';
import {BusyModule} from "angular2-busy";
// import {SandboxModule} from "./sandbox/sandbox.module";
import {AgmCoreModule} from '@agm/core';
import {FileUploadModule} from 'ng2-file-upload';
import {DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule} from 'ngx-dropzone-wrapper';
import {CalendarModule} from 'angular-calendar';
import {YoutubePlayerModule} from "ngx-youtube-player";

import {registerLocaleData} from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import localeSk from '@angular/common/locales/sk';
import "froala-editor/js/froala_editor.pkgd.min.js";

import * as $ from 'jquery';

import {NgxGalleryModule} from "ngx-gallery";
import {PdfViewerModule} from "ng2-pdf-viewer";

import {HomeModule} from "./home/home.module";
import {FlatService} from "./shared/services/flat.service";
import {StateService} from "./shared/services/state.service";

window["$"] = $;
window["jQuery"] = $;

registerLocaleData(localeHu, 'hu');
registerLocaleData(localeSk, 'sk');

const rootRouting: ModuleWithProviders = RouterModule.forRoot([], {useHash: true});

// TODO update, only for testing
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HomeModule,
    rootRouting,
    SharedModule,
    SuiDropdownModule,
    SuiModalModule,
    SuiSelectModule,
    SuiSidebarModule,
    SuiTabsModule,
    SuiDimmerModule,
    SuiPopupModule,
    SuiDatepickerModule,
    SuiSearchModule,
    BrowserAnimationsModule,
    BusyModule,
    SidebarModule.forRoot(),
    // SandboxModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDqEJoBGxf9lb38QXI9J3lcvnUpy-AVnVA',
      libraries: ["places"]
    }),
    FileUploadModule,
    DropzoneModule,
    CalendarModule.forRoot(),
    YoutubePlayerModule,
    NgxGalleryModule,
    PdfViewerModule,
  ],
  providers: [
    FlatService,
    StateService,
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
