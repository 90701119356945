import {Component, Input, OnInit} from '@angular/core';
import {ProjectItem} from "../../../shared/models/building/project-item.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-project-preview',
  templateUrl: './project-preview.component.html',
  styleUrls: ['./project-preview.component.scss']
})
export class ProjectPreviewComponent implements OnInit {


  @Input() project: ProjectItem;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }


  openProjectPage() {
    if(this.project.site){
      const  win = window.open(this.project.site, '_blank');
      win.focus();
      return;
    }
    this.router.navigateByUrl('/home/project/' + this.project.code);
  }


  getBackgroundImageStyle() {
    return {
      'background-image': 'url(/assets/images/projects/' + this.project.code + '/main_cover.jpg)',
    };
  }

}
