import { Injectable } from '@angular/core';


@Injectable()
export class JwtService {

  getToken(): String {
    return window.localStorage['jwtToken'];
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

  getOrgId(): string {
    return window.localStorage['orgId'];
  }

  saveOrgId(token: string) {
    window.localStorage['orgId'] = token;
  }

  getUserId(): string {
    return window.localStorage['userId'];
  }

  saveUserId(token: string) {
    window.localStorage['userId'] = token;
  }

  destroyOrgId() {
    window.localStorage.removeItem('orgId');
  }


}
