import {Component, OnInit} from '@angular/core';
import {Location} from "@angular/common";

@Component({
  selector: 'app-szitas1144',
  templateUrl: './szitas1144.component.html',
  styleUrls: ['./szitas1144.component.scss']
})
export class Szitas1144Component implements OnInit {

  constructor(private location: Location) {

  }

  ngOnInit() {
  }

  backToPrevPage() {
    this.location.back();
  }


}
