import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-map-or-image-tabs',
  templateUrl: './map-or-image-tabs.component.html',
  styleUrls: ['./map-or-image-tabs.component.scss']
})
export class MapOrImageTabsComponent implements OnInit {

  @Output() mapOrImageTabSelected = new EventEmitter();
  @Input() activeTab: string;

  constructor() {
  }

  ngOnInit() {
  }


  isActiveTab(tab) {
    return this.activeTab === tab;
  }

  setActiveTab(tab) {
    this.activeTab = tab;
    this.mapOrImageTabSelected.emit(this.activeTab);
  }
}
