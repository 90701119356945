import {Component, Input, OnInit} from '@angular/core';
import {Event} from "../../models/event.model";
import * as moment from 'moment';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Partner} from "../../models/partner.model";

@Component({
  selector: 'app-event-preview',
  templateUrl: './event-preview.component.html',
  styleUrls: ['./event-preview.component.scss']
})
export class EventPreviewComponent implements OnInit {

  @Input() event: Event;
  @Input() hideRibbon: boolean;
  translates: {};
  organizerPartner: Partner;


  constructor(private translateService: TranslateService,
              private router: Router) {

    this.getTranslates();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslates();
    });

  }


  getPartnerName() {
    return this.organizerPartner.name;
  }

  ngOnInit() {
    this.organizerPartner = this.event.partner;
  }

  getTranslates() {
    this.translateService.get("EVENT").subscribe((response) => {
      this.translates = response;
    })
  }

  getEventTypeName() {
    // if(!this.event.eventType || this.translates[this.event.eventType]){
    //   return null;
    // }

    return this.translates[this.event.eventType];
  }


  getFormattedDate(date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }

  getEventTranslate(key) {
    return ""
  }


  getEventImage() {
    return this.event.imageUrl;
  }


  openEventPage() {
    this.router.navigateByUrl('/event/' + this.event.id);
  }


  openOrganizer() {
    if (this.organizerPartner) {
      this.router.navigateByUrl('/partners');
    }
  }


  isExpired(event: Event) {
    if (moment(event.eventEndDate).diff(moment.now()) < 0) {
      return true;
    }
    return false;
  }

  getBackgroundImageStyle() {
    return {
      'background-image': 'url(' + this.event.imageUrl + ')',
    };
  }
}
