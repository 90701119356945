import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';

@Injectable()
export class ValidatorService {

  constructor() {
  }

  validPhoneNumber(c: FormControl): ValidationErrors {
    if (!c.value) {
      return null;
    }

    const message = {
      'MIN-LENGTH': {
        'minLength': 1
      }
    };

    const pattern = /[^0-9\.]+/g;
    const stripped = c.value.replace(pattern, '');
    if (stripped.length === 0) {
      return message;
    }
    return null;
  }

  validEmail(c: FormControl): ValidationErrors {
    const message = {
      'EMAIL_WRONG': {}
    };

    const pattern = /[^@\.]+/g;
    const stripped = c.value.replace(pattern, '');
    if (stripped.length === 0) {
      return message;
    }
    return null;
  }

  matchPassword(AC: AbstractControl) {
    if (!AC.get('newPasswordAgain').enabled) {
      return null;
    }
    let password = AC.get('newPassword').value; // to get value in input tag
    let confirmPassword = AC.get('newPasswordAgain').value; // to get value in input tag
    if (password && confirmPassword && password != confirmPassword) {
      AC.get('newPasswordAgain').setErrors({MatchPassword: true})
    } else {
      return null
    }
  }



}
