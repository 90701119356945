import {Component, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {PageTypeGroup} from "../../shared/models/page-type-group.model";
import {MapService} from "../../shared/services/map.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {


  pageTypeGroups: PageTypeGroup[];
  highlightedEvents: Event[];

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private router: Router) {


  }

  ngOnInit() {




  }






  transformUrl(video) {

    let videoUrl = '';
    let videoId = this.getYoutubeVideoId(video.videoUrl);
    if (videoId != null || videoId !== 'error') {
      videoUrl = 'https://www.youtube.com/embed/' + videoId;
    } else {
      videoUrl = null;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);

  }



  getYoutubeVideoId(url) {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return 'error';
    }
  }


  openEventPage() {
    this.router.navigateByUrl('/home/events');
  }

}
