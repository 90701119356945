import {Component, OnInit} from '@angular/core';
import {ProjectItem} from '../../shared/models/building/project-item.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  projects: ProjectItem[] = [];

  constructor() {

    let projectItem0 = new ProjectItem();
    projectItem0.name = 'Klapka 21';
    projectItem0.code = 'klapka21';
    projectItem0.latitude = 47.7447164;
    projectItem0.longitude = 18.1149055;
    projectItem0.site = 'http://klapka21.hu';

    let projectItem1 = new ProjectItem();
    projectItem1.name = 'Horizont Lakópark';
    projectItem1.code = 'horizont';
    projectItem1.latitude = 47.6570301;
    projectItem1.longitude = 17.662141;

    let projectItem2 = new ProjectItem();
    projectItem2.name = 'Szitás domb [1144]';
    projectItem2.code = 'szitas1144';
    projectItem2.latitude = 47.7252761;
    projectItem2.longitude = 17.6479649;


    let projectItem3 = new ProjectItem();
    projectItem3.name = 'Szitás domb [1251/1]';
    projectItem3.code = 'szitas12511';
    projectItem3.latitude = 47.724487;
    projectItem3.longitude = 17.654851;

    let projectItem4 = new ProjectItem();
    projectItem4.name = 'Szitás domb [1251/3]';
    projectItem4.code = 'szitas12513';
    projectItem4.latitude = 47.728924;
    projectItem4.longitude = 17.648382;

    this.projects.push(projectItem0);
    this.projects.push(projectItem1);
    this.projects.push(projectItem2);
    this.projects.push(projectItem3);
    this.projects.push(projectItem4);

  }

  ngOnInit() {
  }

}
