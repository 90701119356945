import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-language-tabs',
  templateUrl: './language-tabs.component.html',
  styleUrls: ['./language-tabs.component.scss']
})
export class LanguageTabsComponent implements OnInit {

  @Input() selectedLanguage: string;
  @Input() onBeforeLanguageChange: (currentValue: String, newValue: String) => boolean;
  @Output() languageTabSelected = new EventEmitter();

  supportedLanguages: string[];
  activeTab: string;

  constructor(private translate: TranslateService) {
    this.supportedLanguages = this.translate.getLangs();
  }

  ngOnInit() {
    if (this.selectedLanguage) {
      this.activeTab = this.selectedLanguage;
    } else {
      this.activeTab = this.translate.currentLang;
    }
  }

  isActiveTab(tab) {
    return this.activeTab === tab;
  }

  setActiveTab(tab) {
    let isValid = true;
    if (this.onBeforeLanguageChange) {
      isValid = this.onBeforeLanguageChange(this.activeTab, tab);
    }
    if (isValid) {
      this.activeTab = tab;
      this.languageTabSelected.emit(this.activeTab);
    }
  }

  getFlagClass(languageCode) {

    if (languageCode === 'en') {
      return 'gb';
    }

    return languageCode;

  }

}
