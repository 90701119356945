export const ELSO_EMELET = [
  '1.jpg',
  '2.jpg',
  '4.jpg',
];


export const MASODIK_EMELET = [
  'IMG_5996.JPG',
  'IMG_6017.JPG',
  'IMG_6018.JPG',
  'IMG_6020.JPG',
  'IMG_6028.JPG',
  'IMG_6036.JPG',
  'IMG_6037.JPG',
  'IMG_6039.JPG',
  'IMG_6041.JPG',
  'IMG_6042.JPG',
  'IMG_6043.JPG',
  'IMG_6044.JPG',
  'IMG_6046.JPG',
  'IMG_6047.JPG',
  'IMG_6048.JPG',
  'IMG_6051.JPG',
  'IMG_6057.JPG',
  'IMG_6059.JPG',
  'IMG_6092.JPG',
  'IMG_6094.JPG',
  'IMG_6096.JPG',
  'IMG_6097.JPG',
  'IMG_6100.JPG',
];
export const ALAPLEMEZ_KEREGEROSITES = [
  '1.jpg',
  '10.jpg',
  '11.jpg',
  '12.jpg',
  '13.jpg',
  '14.jpg',
  '15.jpg',
  '2.jpg',
  '3.jpg',
  '5.jpg',
  '6.jpg',
  '7.jpg',
  '8.jpg',
  '9.jpg',
];
export const DRONFOTOK = [
  '49419824_242749236638093_3071508027367489536_o_242749233304760.jpg',
  '49584248_242749879971362_5366249173186248704_o_242749873304696.jpg',
  '49635932_242749963304687_9210967359555108864_o_242749959971354.jpg',
  '49637410_242748639971486_8979861719182475264_o_242748636638153.jpg',
  '49638352_242749279971422_1395517608425422848_o_242749276638089.jpg',
  '49660030_242749923304691_7090690658632990720_o_242749919971358.jpg',
  '49707172_242749986638018_5564724357873270784_o_242749983304685.jpg',
  '49726524_242749343304749_8592511890205179904_o_242749339971416.jpg',
  '49748425_242748739971476_9115967347302072320_o_242748733304810.jpg',
  '49793691_242748679971482_2230716931625713664_o_242748676638149.jpg',
  '49813218_242749949971355_1582807865050005504_o_242749939971356.jpg',
  '49834087_242749833304700_8301288132301553664_o_242749826638034.jpg',
  '49900582_242748773304806_167651106346762240_o_242748769971473.jpg',
  '50041745_242749249971425_7970774601984638976_o_242749246638092.jpg',
  '50221097_242748809971469_4659097732911202304_o_242748806638136.jpg',
  'horizont_01.jpg',
  'horizont_02.jpg',
  'horizont_03.jpg',
  'horizont_04.jpg',
  'horizont_06.jpg',
  'horizont_07.jpg',
  'horizont_08.jpg',
];
export const FOLDMUNKA_TOMBALAP = [
  '1.jpg',
  '10.jpg',
  '11.jpg',
  '12.jpg',
  '15.jpg',
  '16.jpg',
  '17.jpg',
  '18.jpg',
  '19.jpg',
  '2.jpg',
  '20.jpg',
  '21.jpg',
  '22.jpg',
  '23.jpg',
  '24.jpg',
  '3.jpg',
  '4.jpg',
  '5.jpg',
  '6.jpg',
  '7.jpg',
  '8.jpg',
  '9.jpg',


];
export const FOLDSZINT = [
  '1.jpg',
  '10.jpg',
  '11.jpg',
  '12.jpg',
  '14.jpg',
  '2.jpg',
  '3.jpg',
  '4.jpg',
  '5.jpg',
  '6.jpg',
  '7.jpg',
  '8.jpg',
  '9.jpg',
  'IMG_5904.JPG',
  'IMG_5905.JPG',
  'IMG_5906.JPG',
  'IMG_5907.JPG',
  'IMG_5909.JPG',
  'IMG_5910.JPG',
  'IMG_5911.JPG',
  'IMG_5913.JPG',
  'IMG_5914.JPG',
  'IMG_5915.JPG',
  'IMG_5916.JPG',
  'IMG_5917.JPG',
  'IMG_5919.JPG',
  'IMG_5921.JPG',
  'IMG_5923.JPG',
  'IMG_5924.JPG',
  'IMG_5925.JPG',
  'IMG_5926.JPG',
  'IMG_5927.JPG',
  'IMG_5928.JPG',
  'IMG_5929.JPG',
  'IMG_5932.JPG',
  'IMG_5942.JPG',
  'IMG_5943.JPG',
  'IMG_5944.JPG',
  'IMG_5945.JPG',
  'IMG_5948.JPG',
  'IMG_5954.JPG',
  'IMG_5955.JPG',
  'IMG_5956.JPG',

];
export const KULSO_FOTOK = [
  'IMG_20190313_172500.jpg',
  'IMG_20190313_173148.jpg',
  'IMG_20190313_173151.jpg',
  'IMG_20190313_173203.jpg',
  'IMG_20190313_173219.jpg',
  'IMG_5969.JPG',
  'IMG_5971.JPG',
  'IMG_5972.JPG',
  'IMG_5973.JPG',
  'IMG_5981.JPG',
  'IMG_5982.JPG',
  'IMG_5984.JPG',
  'IMG_5985.JPG',
  'IMG_5986.JPG',
  'IMG_5987.JPG',
  'IMG_6022.JPG',
  'IMG_6024.JPG',
  'IMG_6029.JPG',
  'IMG_6031.JPG',
  'IMG_6032.JPG',
  'IMG_6034.JPG',
  'IMG_6035.JPG',
  'IMG_6038.JPG',
  'IMG_6045.JPG',
  'IMG_6098.JPG',
  'IMG_6102.JPG',
  'IMG_6103.JPG',
  'IMG_6108.JPG',
  'IMG_6110.JPG',
  'IMG_6111.JPG',
  'IMG_6112.JPG',
  'IMG_6115.JPG',
  'IMG_6116.JPG',
  'IMG_6117.JPG',
  'IMG_6118.JPG',
  'IMG_6119.JPG',
  'IMG_6120.JPG',
  'IMG_6121.JPG',
  'IMG_6122.JPG',
];
export const PINCESZINT = [
  '1.jpg',
  '10.jpg',
  '12.jpg',
  '14.jpg',
  '16.jpg',
  '19.jpg',
  '21.jpg',
  '22.jpg',
  '23.jpg',
  '24.jpg',
  '25.jpg',
  '26.jpg',
  '27.jpg',
  '3.jpg',
  '31.jpg',
  '4.jpg',
  '5.jpg',
  '6.jpg',
  '7.jpg',
  '8.jpg',
  '9.jpg',
  'IMG_5998.JPG',
  'IMG_5999.JPG',
  'IMG_6000.JPG',
  'IMG_6002.JPG',
  'IMG_6005.JPG',
  'IMG_6006.JPG',
  'IMG_6007.JPG',
  'IMG_6008.JPG',
  'IMG_6009.JPG',
  'IMG_6010.JPG',
  'IMG_6011.JPG',
  'IMG_6012.JPG',
  'IMG_6013.JPG',
  'IMG_6015.JPG',
];
export const VALASZFALAZAS = [
  'IMG_6025.JPG',
  'IMG_6026.JPG',
  'IMG_6027.JPG',
  'IMG_6030.JPG',
];
export const VASSZERELES = [
  'IMG_6062.JPG',
  'IMG_6063.JPG',
  'IMG_6064.JPG',
  'IMG_6065.JPG',
  'IMG_6067.JPG',
  'IMG_6069.JPG',
  'IMG_6070.JPG',
  'IMG_6071.JPG',
  'IMG_6073.JPG',
  'IMG_6075.JPG',
  'IMG_6077.JPG',
  'IMG_6078.JPG',
  'IMG_6079.JPG',
  'IMG_6080.JPG',
  'IMG_6082.JPG',
  'IMG_6084.JPG',
  'IMG_6085.JPG',
  'IMG_6087.JPG',
  'IMG_6089.JPG',
  'IMG_6090.JPG',
  'IMG_6091.JPG',
  'IMG_6099.JPG'
];
export const VILLANYSZERELES = [
  'IMG_5974.JPG',
  'IMG_5975.JPG',
  'IMG_5976.JPG',
  'IMG_5977.JPG',
  'IMG_5978.JPG',
  'IMG_5979.JPG',
  'IMG_5980.JPG',
  'IMG_5983.JPG'
];

export const ELKESZULT_EPULET = [
  '1_IMG_6988.jpg',
  '2_IMG_7034.jpg',
  '3_IMG_7021.jpg',
  '4_IMG_7022.jpg',
  '5_IMG_7025.jpg',
  '6_IMG_7028.jpg',
  '7_IMG_7038.jpg',
  '8_IMG_6919.jpg',
  '9_IMG_6949.jpg',
  '10_IMG_6968.jpg',
  '11_IMG_6974.jpg',
  '12_IMG_7012.jpg',
];



