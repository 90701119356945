import {Component, HostListener, OnInit, ViewContainerRef} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {User} from './shared/models';
import {Subscription} from 'rxjs/Subscription';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import {StateService} from "./shared/services/state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit {

  logoutTitle: string;
  logoutQuestion: string;
  busy: Subscription;
  currentUser: User;
  isSidebarOpen: Boolean = false;
  stickScrollDownStateFalse: boolean = false;
  isScrolledDown: Boolean = false;
  pageHeight: number;

  constructor(private translate: TranslateService,
              private vcr: ViewContainerRef,

              private router: Router,
              private route: ActivatedRoute,
              private stateService: StateService) {

    //Set supported languages
    translate.addLangs(['hu', 'en', 'de', 'hr']);
    translate.setDefaultLang('hu');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('hu');

    this.getTranslations();

    this.stateService.setSelectedSideMenuState(false);
    this.stateService.setNavigationLoaderState(false);

    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    });


    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.stateService.setNavigationLoaderState(true);
      }

      if (event instanceof NavigationEnd) {
        this.stateService.setNavigationLoaderState(false);

        if (event.url.includes('tour/')
          || event.url.includes('waypoint/')
          || event.url.includes('article/')
          || event.url.includes('hostPartner/')
          || event.url.includes('event/')
          || event.url.includes('admin')
          || event.url.includes('profile')
          || event.url.includes('project/')) {

          // this.setScrollDownState(true, event.url);
          this.stickScrollDownStateFalse = true;
        } else {
          // this.setScrollDownState(false, event.url);
          this.stickScrollDownStateFalse = false;
        }

        // $('body,html').animate({
        //   scrollTop: 0
        // }, 0);


        setTimeout(()=>{
          if(document.getElementsByClassName('page-container') && document.getElementsByClassName('page-container').length){
            this.pageHeight = document.getElementsByClassName('page-container')[0].getBoundingClientRect().height;
            if (this.pageHeight > 774 && this.pageHeight < 900 && !document.getElementsByClassName('page-container')[0].classList.contains('scroll-overflow')) {
              document.getElementsByClassName('page-container')[0].className += " scroll-overflow";
            }
            else {
              document.getElementsByClassName('page-container')[0].classList.remove('scroll-overflow');
            }
          }
        }, 100);
      }

      if (event instanceof NavigationError) {
        this.stateService.setNavigationLoaderState(false);
      }

      if (event instanceof NavigationCancel) {
        this.stateService.setNavigationLoaderState(false);
      }

      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

  }

  setScrollDownState(scrollDown: boolean, url) {
    if (scrollDown) {
      document.getElementById("main-header-menu").className = "ui top fixed menu main-header-menu scroll-down";
      document.getElementById("main-content-container").className = "main-content-container low-header";
      document.getElementById("return-to-top").className = "return-to-top";
    } else {
      document.getElementById("main-header-menu").className = "ui top fixed menu main-header-menu";
      document.getElementById("main-content-container").className = "main-content-container";
      document.getElementById("return-to-top").className = "return-to-top hide";
    }

    if(url && (url.includes('admin') || url.includes('profile'))){
      document.getElementById("return-to-top").className = "return-to-top hide";
    }
  }


  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event) {
  //
  //   // if (this.stickScrollDownStateFalse || (this.pageHeight && this.pageHeight < 1000)) {
  //   if (this.stickScrollDownStateFalse) {
  //     return;
  //   }
  //
  //   if ($(window).scrollTop() > 1) {
  //     this.setScrollDownState(true, null);
  //   } else {
  //     this.setScrollDownState(false, null);
  //   }
  // }

  ngOnInit() {



    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] === '/') {
          this.router.navigateByUrl('/home/main');
        }
      }
    });

    this.removeSplashScreen();
  }

  getTranslations() {
    this.translate.get('MENU.LOG_OUT').subscribe((res: string) => {
      this.logoutTitle = res;
    });
    this.translate.get('QUESTIONS.LOG_OUT').subscribe((res: string) => {
      this.logoutQuestion = res;
    });
  };



  dimmedChanged() {
    this.stateService.setSelectedSideMenuState(this.isSidebarOpen);
  }


  removeSplashScreen() {
    document.getElementById("content-wrapper").className = "";
    document.getElementById("splash").className = "display-none";
    document.getElementsByTagName("body")[0].className = "";

    // $('#content-wrapper').removeClass('display-none');
    // $('#splash').addClass('display-none');
  }


}
