import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {
  SuiAccordionModule,
  SuiCheckboxModule,
  SuiDatepickerModule,
  SuiDimmerModule,
  SuiDropdownModule,
  SuiMessageModule,
  SuiModalModule,
  SuiPopupModule,
  SuiRatingModule,
  SuiSelectModule,
  SuiSidebarModule,
  SuiTabsModule
} from 'ng2-semantic-ui';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AgmCoreModule} from '@agm/core';

import {DropzoneModule} from 'ngx-dropzone-wrapper';

import {AppHeaderComponent} from './layout/app-header/app-header.component';
import {FooterComponent} from './layout/footer.component';
import {BrandImageComponent} from './layout/brand-image/brand-image.component';

import {BusyModule} from 'angular2-busy';

import {EventCalendarComponent} from './event-helpers';
import {CalendarModule} from 'angular-calendar';

import {PdfViewerWrapperComponent} from './layout/pdf-viewer-wrapper/pdf-viewer-wrapper.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {CarouselModule} from 'ngx-bootstrap';
import {LocalizedDatePipe} from './pipes/localized-date-pipe';

import {CustomSideMenuComponent} from './layout/custom-side-menu/custom-side-menu.component';

import {NavigationLoaderComponent} from './layout/navigation-loader/navigation-loader.component';
import {ReturnToTopComponent} from './layout/return-to-top/return-to-top.component';


import {ArraySortPipe} from "./pipes/sort-by.pipe";
import "froala-editor/js/froala_editor.pkgd.min.js";
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {ProjectsMapComponent} from './layout/projects-map/projects-map.component';
import { HorizontCarouselComponent } from './layout/horizont-carousel/horizont-carousel.component';
import { ContactSectionComponent } from './layout/contact-section/contact-section.component';
import { PartnerSectionComponent } from './layout/partner-section/partner-section.component';
import { ImageMapComponent } from './layout/image-map/image-map.component';
import { FlatMapSectionComponent } from './layout/flat-map-section/flat-map-section.component';
import {EventPreviewComponent} from "./event-helpers/event-preview/event-preview.component";
import {MapOrImageTabsComponent} from "./layout/map-or-image-tabs/map-or-image-tabs.component";
import {LanguageTabsComponent} from "./layout/language-tabs/language-tabs.component";
import {LazyLoadImagesModule} from "ngx-lazy-load-images";
import { Szitas1144CarouselComponent } from './layout/szitas1144-carousel/szitas1144-carousel.component';
import {NgxGalleryModule} from "ngx-gallery";
import { FlatPreviewItemComponent } from './layout/flat-preview-item/flat-preview-item.component';
import {Szitas12513CarouselComponent} from "./layout/szitas12513-carousel/szitas12513-carousel.component";
import {Szitas12511CarouselComponent} from "./layout/szitas12511-carousel/szitas12511-carousel.component";
import { IndoorImagesComponent } from './layout/indoor-images/indoor-images.component';
import { SoldOutComponent } from './layout/sold-out/sold-out.component';
import { NgxMasonryModule } from 'ngx-masonry';
import {MasonryGridWrapper} from './layout/masonry-grid-wrapper/masonry-grid-wrapper.component';
import {GalleryLoadingSpinnerComponent} from './layout/gallery-loading-spinner/gallery-loading-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    SuiDropdownModule,
    SuiModalModule,
    SuiSelectModule,
    SuiSidebarModule,
    SuiTabsModule,
    SuiMessageModule,
    SuiCheckboxModule,
    SuiAccordionModule,
    SuiDatepickerModule,
    SuiDimmerModule,
    SuiPopupModule,
    SuiRatingModule,
    BrowserAnimationsModule,
    TranslateModule,
    AgmCoreModule,
    DropzoneModule,
    BusyModule,
    CalendarModule,
    PdfViewerModule,
    LeafletModule,
    CarouselModule.forRoot(),
    FroalaEditorModule,
    FroalaViewModule,
    LazyLoadImagesModule,
    NgxGalleryModule,
    NgxMasonryModule
  ],
  declarations: [
    EventCalendarComponent,
    AppHeaderComponent,
    FooterComponent,
    BrandImageComponent,
    PdfViewerWrapperComponent,

    LocalizedDatePipe,
    CustomSideMenuComponent,
    NavigationLoaderComponent,
    ReturnToTopComponent,
    ArraySortPipe,
    ProjectsMapComponent,
    HorizontCarouselComponent,
    ContactSectionComponent,
    PartnerSectionComponent,
    ImageMapComponent,
    FlatMapSectionComponent,
    EventPreviewComponent,
    MapOrImageTabsComponent,
    LanguageTabsComponent,
    Szitas1144CarouselComponent,
    Szitas12511CarouselComponent,
    Szitas12513CarouselComponent,
    FlatPreviewItemComponent,
    IndoorImagesComponent,
    SoldOutComponent,
    MasonryGridWrapper,
    GalleryLoadingSpinnerComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    SuiSelectModule,
    SuiDropdownModule,
    SuiModalModule,
    SuiSelectModule,
    SuiSidebarModule,
    SuiTabsModule,
    SuiMessageModule,
    SuiCheckboxModule,
    SuiAccordionModule,
    SuiDatepickerModule,
    SuiDimmerModule,
    NgxGalleryModule,
    SuiPopupModule,
    SuiRatingModule,
    TranslateModule,
    AppHeaderComponent,
    FooterComponent,
    BrandImageComponent,
    PdfViewerWrapperComponent,
    LocalizedDatePipe,
    CustomSideMenuComponent,
    NavigationLoaderComponent,
    ReturnToTopComponent,
    ArraySortPipe,
    ProjectsMapComponent,
    HorizontCarouselComponent,
    ContactSectionComponent,
    PartnerSectionComponent,
    ImageMapComponent,
    FlatMapSectionComponent,
    LazyLoadImagesModule,
    Szitas1144CarouselComponent,
    Szitas12511CarouselComponent,
    Szitas12513CarouselComponent,
    FlatPreviewItemComponent,
    IndoorImagesComponent,
    SoldOutComponent,
    MasonryGridWrapper,
    GalleryLoadingSpinnerComponent
  ],
  entryComponents: []
})
export class SharedModule {
}
