import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Rx';

@Component({
  selector: 'app-masonry-grid-wrapper',
  templateUrl: './masonry-grid-wrapper.component.html',
  styleUrls: ['./masonry-grid-wrapper.component.scss'],
})
export class MasonryGridWrapper implements OnInit {
  @Input()
  set items(items: any[]) {
    this.gridItems = items;
  }
  @Input() itemLimit: number;
  @Input()
  set columns(val: number) {
    this.colCount = val;
  }
  @Input() options: any; // @TODO: ennek NgxMasonryOptions a tipusa, csak tslintnek nem tetszik
  @Output() onload = new EventEmitter();
  @Output() elementButtonClick = new EventEmitter();
  @Output() elementClicked = new EventEmitter();

  @ViewChild('cont') cont;

  gridItems: any[];
  colCount: number;
  limitedItems: string[];
  loading: boolean;
  currentLimit: number;
  itemWidth: number;
  onLlayoutComplete = new EventEmitter();

  constructor() {
    this.colCount = 2;
    this.itemLimit = 100;
    this.currentLimit = this.itemLimit;
    this.options = {
      transitionDuration: '0s',
      gutter: 20,
    };

    Observable.fromEvent(window, 'resize')
      .debounceTime(100)
      .subscribe(() => {
        this.calculateItemWidth();
      });

    Observable.from(this.onLlayoutComplete)
      .debounceTime(100)
      .subscribe(() => {
        this.onload.emit({
          masonryHeight: this.cont.nativeElement.offsetHeight,
        });
      });
  }



  calculateItemWidth() {
    this.itemWidth =
      (this.cont.nativeElement.offsetWidth - this.options.gutter * (this.colCount - 1)) /
      this.colCount;
  }

  ngOnInit() {
    this.calculateItemWidth();
    this.limitation();

    document.onwheel = (e) => {
      if (!this.loading && document.body.scrollHeight - window.scrollY < window.innerHeight + 200) {
        this.showMore();
      }
    };
  }

  limitation() {
    this.limitedItems = this.gridItems.slice(0, this.currentLimit);
  }

  showMore() {
    this.loading = true;
    this.currentLimit = Math.min(this.currentLimit + this.itemLimit, this.gridItems.length);
    this.limitation();

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }



  layoutComplete() {
    this.onLlayoutComplete.emit();
  }
}
