import {Component, OnInit, ViewChild} from '@angular/core';
import {Flat} from "../../../shared/models/building/flat.model";
import {Location} from "@angular/common";
import {FlatService} from "../../../shared/services/flat.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from 'underscore';
import {FlatArea} from "../../../shared/models/building/flat-area.model";
import {NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";

@Component({
  selector: 'app-flat',
  templateUrl: './flat.component.html',
  styleUrls: ['./flat.component.scss']
})
export class FlatComponent implements OnInit {

  projectId: string;
  flat: Flat;
  flatId: string;
  visualizationImageLoaded: boolean;
  shareUrl = 'http://horizontlakopark.hu/share/flat.php?id=';
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  @ViewChild('ngxGalleryRef') ngxGalleryRef: NgxGalleryComponent;

  constructor(private location: Location,
              private flatService: FlatService,
              private router: Router,
              private route: ActivatedRoute) {
    this.initFacebookApp();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      console.log('params', params);
      this.projectId = this.route.snapshot.params['projectId'];
      this.flatId = this.route.snapshot.params['id'];


      this.flat = this.flatService.getFlatFromProject(this.projectId, Number(this.flatId));
      this.initGallery();
    });

  }

  backToPrevPage() {
    this.location.back();
  }

  removeHuf(priceString) {
    return priceString.replace("HUF", "");
  }

  getTerraceSize() {

    let terraceSize = 0;
    let terraceSizeText = "";

    _.forEach(this.flat.flatAreas, (flatArea: FlatArea) => {
      if (flatArea.name === "terasz") {
        terraceSize = terraceSize + flatArea.size;
      }
    });


    if (terraceSize != 0) {
      terraceSizeText = parseFloat(terraceSize.toString()).toFixed(2);
    }
    return terraceSizeText
  }

  getFlatRectPosition(getX: boolean) {

    if (!this.visualizationImageLoaded) {
      return 0;
    }

    let elements = document.getElementsByClassName("visualization-image");

    if (!elements || !elements.length) {
      return 0;
    }

    let element = document.getElementsByClassName("visualization-image")[0];
    let ratio = element['naturalWidth'] / element['offsetWidth'];

    if (getX) {
      return (this.flat.coordinateX / ratio) - (25 / ratio);
    } else {
      return (this.flat.coordinateY / ratio) - (25 / ratio);
    }
  }

  getFlatRectHeight() {

    if (!this.visualizationImageLoaded) {
      return 0;
    }

    let elements = document.getElementsByClassName("visualization-image");

    if (!elements || !elements.length) {
      return 0;
    }

    let element = document.getElementsByClassName("visualization-image")[0];
    let ratio = element['naturalWidth'] / element['offsetWidth'];
    return 50 / ratio;

  }

  openProjectPage() {
    this.router.navigateByUrl('/home/project/' + this.flat.project.toLowerCase());
  }

  initFacebookApp() {
    if (!window['fbAsyncInit']) {
      window['fbAsyncInit'] = function () {
        window['FB'].init({
          autoLogAppEvents: true,
          xfbml: true,
          appId: '170555663821545',
          version: 'v3.0'
        });
      };
    }

    // load facebook sdk if required
    const url = 'https://connect.facebook.net/en_US/sdk.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    }
  }

  shareOnFacebook() {
    let flatName = this.flat.name.replace("/", "").replace("/", "");
    window['FB'].ui({
      method: 'share',
      href: this.shareUrl + flatName,
    }, (response) => {

    });
  }

  initGallery() {

    this.galleryOptions = [
      {
        image: false,
        thumbnails: false,
        width: "0px",
        height: "0px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowPrevIcon: "icon angle left",
        arrowNextIcon: "icon angle right",
        previewSwipe: true,
        thumbnailsSwipe: true
      }
    ];

    this.galleryImages = [];

    let image1Url = this.getFlatPositionImageUrl();
    let image2Url = "/assets/images/projects/" + this.flat.project.toLowerCase() + "/flats/" + this.flat.id + "_P.jpg";
    let image3Url = "/assets/images/projects/" + this.flat.project.toLowerCase() + "/flats/" + this.flat.id + "_A.jpg";

    this.galleryImages.push({
      small: image1Url,
      medium: image1Url,
      big: image1Url
    });

    this.galleryImages.push({
      small: image2Url,
      medium: image2Url,
      big: image2Url
    });

    this.galleryImages.push({
      small: image3Url,
      medium: image3Url,
      big: image3Url
    });
  }


  openNgxGallery(index): void {
    this.ngxGalleryRef.openPreview(index);
  }

  getFlatPositionImageUrl() {

    if (!this.flat || !this.flat['name']) {
      return "";
    }


    if (this.flat.concatImageUrl) {
      return this.flat.concatImageUrl;
    }


    let flatNameInImage = this.flat.name.replace("/", "_").replace("/", "_").toUpperCase();
    return '/assets/images/projects/' + this.flat.project.toLowerCase() + '/flats/flat_position/' + flatNameInImage + '_p.jpg'
  }

}
