import {Component, OnInit} from '@angular/core';
import {StateService} from "../shared/services/state.service";
import {Router} from "@angular/router";

const NUMBER_CLASSES = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isSidebarOpen: Boolean = false;

  constructor(private stateService: StateService,
              private router: Router) {
    this.stateService.sideMenuState.subscribe((state) => {
      this.isSidebarOpen = state;
    })
  }

  ngOnInit() {
  }


  toggleSideBar() {
    this.stateService.setSelectedSideMenuState(!this.isSidebarOpen);
  }



}
