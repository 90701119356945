import { Component, OnInit } from '@angular/core';
import {MapService} from "../../services/map.service";

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent implements OnInit {

  contactMap: any;
  customGoogleMapStyle: any;


  constructor() {

    this.customGoogleMapStyle = MapService.getCustomMapStyle();

  }

  onMapReady(map) {
    this.contactMap = map;
    this.contactMap.styles = this.customGoogleMapStyle;
  }

  ngOnInit() {
  }

}
