import {ProductTranslate} from "./product-translate.model";

export class Product {
  id?: number;
  name?: string;
  description?: string;
  imageUrl?: string;
  isFavorite?: boolean;
  productTranslates?: ProductTranslate[];
}
