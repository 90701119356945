import {ModuleWithProviders, NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {RouterModule} from "@angular/router";
import {MainComponent} from './main/main.component';
import {SharedModule} from "../shared/shared.module";
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {BusyModule} from "angular2-busy";
import {SuiPaginationModule} from "ng2-semantic-ui";
import {FlatComponent} from "./flats/flat/flat.component";
import {FlatsComponent} from "./flats/flats.component";
import {ProjectsComponent} from './projects/projects.component';
import {ServicesComponent} from './services/services.component';
import {ContactComponent} from './contact/contact.component';
import {HorizontPageComponent} from './projects/horizont-page/horizont-page.component';
import {ProjectPreviewComponent} from './projects/project-preview/project-preview.component';
import {AgmCoreModule} from "@agm/core";
import {LazyLoadImagesModule} from "ngx-lazy-load-images";

import { Szitas1144Component } from './projects/szitas1144/szitas1144.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { Szitas12511Component } from './projects/szitas12511/szitas12511.component';
import { Szitas12513Component } from './projects/szitas12513/szitas12513.component';
import { TechnicalDescriptionComponent } from './technical-description/technical-description.component';
import { GalleryComponent } from './gallery/gallery.component';


const homeRouting: ModuleWithProviders = RouterModule.forChild([
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: 'main',
        component: MainComponent
      },
      {
        path: 'flats',
        component: FlatsComponent
      },
      {
        path: 'technical-description',
        component: TechnicalDescriptionComponent
      },
      {
        path: 'flat/:projectId/:id',
        component: FlatComponent
      },
      {
        path: 'services',
        component: ServicesComponent
      },
      {
        path: 'projects',
        component: ProjectsComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'project/horizont',
        component: HorizontPageComponent
      },
      {
        path: 'project/szitas1144',
        component: Szitas1144Component
      },
      {
        path: 'project/szitas12511',
        component: Szitas12511Component
      },
      {
        path: 'project/szitas12513',
        component: Szitas12513Component
      },
      {
        path: 'gallery/horizont',
        component: GalleryComponent
      }
    ]
  }
]);

@NgModule({
  imports: [
    homeRouting,
    SharedModule,
    FroalaViewModule,
    FroalaEditorModule,
    BusyModule,
    SuiPaginationModule,
    AgmCoreModule,
    LazyLoadImagesModule,
    DeferLoadModule,
  ],
  declarations: [
    HomeComponent,
    MainComponent,
    FlatsComponent,
    FlatComponent,
    ProjectsComponent,
    ServicesComponent,
    ContactComponent,
    HorizontPageComponent,
    ProjectPreviewComponent,
    Szitas1144Component,
    Szitas12511Component,
    Szitas12513Component,
    TechnicalDescriptionComponent,
    GalleryComponent
  ],
  providers: []
})
export class HomeModule {
}
