export class FlatArea {
  name?: string;
  size?: number;
  coverage?: string;
  number?: number;

  constructor(number, name, size, coverage){
    this.number = number;
    this.name = name;
    this.size = size;
    this.coverage = coverage;
  }

}
