import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Flat} from "../../models/building/flat.model";
import * as _ from 'underscore';
import {FlatService} from "../../services/flat.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-horizont-carousel',
  templateUrl: './horizont-carousel.component.html',
  styleUrls: ['./horizont-carousel.component.scss']
})
export class HorizontCarouselComponent implements OnInit, OnDestroy {

  @Input() project: string;
  @Input() hideProjectLabel: boolean;
  images: object[] = [];
  activeSlideIndex: number = 0;
  activeSlide: {};
  highlightedFlat: Flat;
  flats: Flat[];
  constructor(private myElement: ElementRef,
              private flatService: FlatService,
              private router: Router) {
    this.flatService.flats.subscribe((data) => {
      this.flats = data;
    })
  }

  ngOnInit() {
    if (this.project === 'HORIZONT') {

      this.images.push(
        {
          side: 'FRONT',
          url: '/assets/images/projects/horizont/sight/front_carousel.jpg'
        }
      );

      this.images.push(
        {
          side: 'LEFT',
          url: '/assets/images/projects/horizont/sight/left_carousel.jpg'
        }
      );

      this.images.push(
        {
          side: 'RIGHT',
          url: '/assets/images/projects/horizont/sight/right_carousel.jpg'
        }
      );

      this.images.push(
        {
          side: 'BEHIND',
          url: '/assets/images/projects/horizont/sight/behind_carousel.jpg'
        }
      );
    }

  }

  ngOnDestroy() {
    let elements = document.getElementsByClassName("carousel-wrapper");


    for(let index = 0; index < elements.length; index++){
      elements[index].removeEventListener("mousemove", null);
      elements[index].removeEventListener("click", null);
    }
  }


  carouselImageLoaded(){
    this.initMouseMoveListener(1000);
  }

  initMouseMoveListener(timeout) {
    setTimeout(() => {

      let element = document.getElementsByClassName("carousel-item active")[0].getElementsByTagName("img")[0];
      let ratio = element.naturalWidth / element.offsetWidth;

      document.getElementsByClassName("carousel-wrapper")[0].addEventListener("mousemove", (event) => {
        this.highlightedFlat = null;
        _.forEach(this.flats, (flat: Flat) => {

          if (!flat.side || flat.side !== this.activeSlide['side']) {
            return;
          }

          let mouseX = Number(event['x'] * ratio);
          let mouseY = Number(event['offsetY'] * ratio);

          let flatX = flat.coordinateX;
          let flatY = flat.coordinateY;

          if ((mouseX > flatX - 50 && mouseX < flatX + 50) && (mouseY > flatY - 50 && mouseY < flatY + 50)) {
            this.highlightedFlat = flat;
          }
        });


      });

      document.getElementsByClassName("carousel-wrapper")[0].addEventListener("click", (event) =>{
        _.forEach(this.flats, (flat: Flat) => {

          if (!flat.side || flat.side !== this.activeSlide['side']) {
            return;
          }

          let mouseX = Number(event['x'] * ratio);
          let mouseY = Number(event['offsetY'] * ratio);

          let flatX = flat.coordinateX;
          let flatY = flat.coordinateY;

          if ((mouseX > flatX - 50 && mouseX < flatX + 50) && (mouseY > flatY - 50 && mouseY < flatY + 50)) {
            this.router.navigateByUrl('/home/flat/' + flat.project + "/" + flat.id);
          }
        });
      })


    }, timeout)
  }


  onActiveSlideChange(index) {
    this.activeSlideIndex = index;
    this.activeSlide = this.images[this.activeSlideIndex];
    this.initMouseMoveListener(0);
  }

  setActiveSlideIndex(index) {
    this.activeSlideIndex = index;
  }


  removeHuf(priceString) {
    return priceString.replace("HUF", "");
  }

  getHightLightedFlat(getX: boolean) {
    let elements = document.getElementsByClassName("carousel-item active");

    if (!elements || !elements.length) {
      return 0;
    }

    let element = document.getElementsByClassName("carousel-item active")[0].getElementsByTagName("img")[0];
    let ratio = element.naturalWidth / element.offsetWidth;

    if (getX) {
      return this.highlightedFlat.coordinateX / ratio;
    } else {
      return (this.highlightedFlat.coordinateY / ratio) - 120;
    }
  }

  getHightLightedFlatRectPos(getX: boolean) {
    let elements = document.getElementsByClassName("carousel-item active");

    if (!elements || !elements.length) {
      return 0;
    }

    let element = document.getElementsByClassName("carousel-item active")[0].getElementsByTagName("img")[0];
    let ratio = element.naturalWidth / element.offsetWidth;

    if (getX) {
      return (this.highlightedFlat.coordinateX / ratio) - 25;
    } else {
      return (this.highlightedFlat.coordinateY / ratio) - 25;
    }
  }


  openFlatPage() {
    this.router.navigateByUrl('/home/flat/' + this.highlightedFlat.project + "/" + this.highlightedFlat.id);
  }


}
