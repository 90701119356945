import {Event} from './event.model';

export class ArticleEvent {
  id: number;
  articleId?: number;
  eventId: number;
  event: Event;

  assigned?: boolean;
}
