import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-to-top',
  templateUrl: './return-to-top.component.html',
  styleUrls: ['./return-to-top.component.scss']
})
export class ReturnToTopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  returnToTop(){
    // $('body,html').animate({
    //   scrollTop : 0                       // Scroll to top of body
    // }, 500);
  }

}
