import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import 'rxjs/add/operator/distinctUntilChanged';

@Injectable()
export class StateService {

  public sideMenuStateSubject = new BehaviorSubject<Boolean>(new Boolean());
  public sideMenuState = this.sideMenuStateSubject.asObservable().distinctUntilChanged();

  public navigationLoaderStateSubject = new BehaviorSubject<Boolean>(new Boolean());
  public navigationLoaderState = this.navigationLoaderStateSubject.asObservable().distinctUntilChanged();

  constructor() {
  }

  setSelectedSideMenuState(sideMenuState) {
    this.sideMenuStateSubject.next(sideMenuState);
  }


  setNavigationLoaderState(state) {
    this.navigationLoaderStateSubject.next(state);
  }


}
