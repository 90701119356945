import {Permission} from './permission.model';
import {Nuts3} from './nuts3.model';
import {Partner} from "./partner.model";

export class User {
  appToken?: string;
  imageUrl?: string;
  id?: number;
  isActive?: boolean;
  name?: string;
  email?: string;
  permissions?: Permission;
  nuts3?: Nuts3;
  partner?: Partner;
  language?: string;
  phone: string;
  eventUserStatus?: string;
}
