import {User} from "./user.model";

export class Post {
  id?: number;
  topicId?: number;
  user?: User;
  content?: string;
  createdDate: string;
  modifiedDate: string;
}
