import {Component, Input, OnInit} from '@angular/core';
import {Flat} from "../../models/building/flat.model";
import {Router} from "@angular/router";
import * as _ from 'underscore';

@Component({
  selector: 'app-flat-preview-item',
  templateUrl: './flat-preview-item.component.html',
  styleUrls: ['./flat-preview-item.component.scss']
})
export class FlatPreviewItemComponent implements OnInit {

  @Input() flat: Flat;

  constructor(private router: Router) {
  }

  ngOnInit() {

    // let downloadingImage = new Image();
    // downloadingImage.src = '/assets/images/projects/' + this.flat.project.toLowerCase() + '/flats/small/' + this.flat.id + '_P.jpg';
    // downloadingImage.onload = () => {
    //   console.log("onload");
    //   // image.src = this.src;
    // };
  }


  openFlatPage(flat) {
    this.router.navigateByUrl('/home/flat/' + flat.project + "/" + flat.id);
  }


  getTerraceSize(flat) {
    return _.findWhere(flat.flatAreas, {
      name: "terasz"
    })['size'];
  }

  removeHuf(priceString) {
    return priceString.replace("HUF", "");
  }


}
