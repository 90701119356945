import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HORIZONT_IMAGES} from './horizont_gallery';
import {NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {
  ALAPLEMEZ_KEREGEROSITES, DRONFOTOK, ELKESZULT_EPULET,
  ELSO_EMELET,
  FOLDMUNKA_TOMBALAP,
  FOLDSZINT, KULSO_FOTOK,
  MASODIK_EMELET,
  PINCESZINT, VALASZFALAZAS, VASSZERELES, VILLANYSZERELES
} from '../../shared/const/gallery_images';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit {


  @ViewChild('gridWrapper') gridWrapper;
  @ViewChild('ngxGalleryRef') ngxGalleryRef: NgxGalleryComponent;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  items: CarouselMediaItemModel[] = [];
  gridColumnCount: number;
  updateLayout: boolean;
  selectedGalleryType: GalleryTypeInterface = null;
  galleryTypes: GalleryTypeInterface[] = [
    {name: 'földmunka+tömbalap', folderId: 'foldmunka_tombalap', images: FOLDMUNKA_TOMBALAP},
    {name: 'alaplemez + kéregerősítés', folderId: 'alaplemez_keregerosites', images: ALAPLEMEZ_KEREGEROSITES},
    {name: 'pinceszint', folderId: 'pinceszint', images: PINCESZINT},
    {name: 'földszint', folderId: 'foldszint', images: FOLDSZINT},
    {name: '1. emelet', folderId: 'elso_emelet', images: ELSO_EMELET},
    {name: '2. emelet', folderId: 'masodik_emelet', images: MASODIK_EMELET},
    {name: 'drónfotók', folderId: 'dronfotok', images: DRONFOTOK},
    {name: 'külső fotók', folderId: 'kulso_fotok', images: KULSO_FOTOK},
    {name: 'vasszerelés', folderId: 'vasszereles', images: VASSZERELES},
    {name: 'válaszfalazás', folderId: 'valaszfalazas', images: VALASZFALAZAS},
    {name: 'villanyszerelés', folderId: 'villanyszereles', images: VILLANYSZERELES},
    {name: 'elkészült épület', folderId: 'elkeszultepulet', images: ELKESZULT_EPULET}
  ];
  imageUrlPrefix: string = 'assets/images/projects/horizont/gallery/';

  constructor() {
    this.galleryOptions = [
      {
        image: false,
        thumbnails: false,
        width: '0px',
        height: '0px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowPrevIcon: 'icon angle left',
        arrowNextIcon: 'icon angle right',
        previewSwipe: true,
        thumbnailsSwipe: true
      }
    ];

    // for (let item of HORIZONT_IMAGES) {
    //   this.items.push({
    //     src: 'assets/images/projects/horizont/gallery/thumb/' + item,
    //   });
    //
    //   this.galleryImages.push({
    //     small: 'assets/images/projects/horizont/gallery/full/' + item,
    //     medium: 'assets/images/projects/horizont/gallery/full/' + item,
    //     big: 'assets/images/projects/horizont/gallery/full/' + item
    //   });
    // }

    Observable.fromEvent(window, 'resize')
      .debounceTime(100)
      .subscribe(() => {
        this.calculateGridColumnCount();
      });


  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.calculateGridColumnCount();
    // }, 2501)
  }

  ngOnInit() {
  }

  setSelectedGalleryType(galleryType) {
    this.selectedGalleryType = galleryType;
    this.items = [];
    this.galleryImages = [];
    this.gridColumnCount = 0;


    setTimeout(() => {

      for (let item of this.selectedGalleryType.images) {
        this.items.push({
          src: this.imageUrlPrefix + galleryType.folderId + '/' + item,
        });

        this.galleryImages.push({
          small: this.imageUrlPrefix + galleryType.folderId + '/' + item,
          medium: this.imageUrlPrefix + galleryType.folderId + '/' + item,
          big: this.imageUrlPrefix + galleryType.folderId + '/' + item
        });
      }
// this.updateLayout = this.updateLayout
      setTimeout(() => {
        this.calculateGridColumnCount();
      }, 100)


    });
  }


  gridLoaded(e) {
    this.gridWrapper.nativeElement.style.height = 'auto';
  }

  masonryElementClicked(index) {
    this.ngxGalleryRef.openPreview(index);

  }


  calculateGridColumnCount() {


    let colCount = 4;

    if (window.innerWidth < 640) {
      colCount = 1;
    } else if (window.innerWidth < 800) {
      colCount = 2;
    } else if (window.innerWidth < 1280) {
      colCount = 3;
    }

    this.gridColumnCount = colCount;
  }


  getBackgroundImageStyle(galleryType) {
    return {
      'background-image': 'url(' + this.imageUrlPrefix + galleryType.folderId + '/' + galleryType.images[0] + ')',
    };
  }


}

interface GalleryTypeInterface {
  name: string;
  folderId: string;
  images: string[]
}

export interface CarouselMediaItemModel {
  src: string;
  width?: number;
  height?: number;
  type?: string;
  newWidth?: number;
  newHeight?: number;
  widthBefore?: number;
  gid?: string;
  children?: CarouselMediaItemModel[];
}
