import {Component, Input, OnInit} from '@angular/core';
import {FlatService} from "../../services/flat.service";
import {Flat} from "../../models/building/flat.model";
import * as _ from 'underscore';

@Component({
  selector: 'app-flat-map-section',
  templateUrl: './flat-map-section.component.html',
  styleUrls: ['./flat-map-section.component.scss']
})
export class FlatMapSectionComponent implements OnInit {

  @Input() highlightedFlat: Flat;

  flatsByLevel: object = {};
  activeLevel: number = 0;
  allFlat: Flat[];

  constructor(private flatService: FlatService) {

    this.flatService.flats.subscribe((data) => {
      this.allFlat = data;
      this.initFlatsByLevel();
    });

  }

  ngOnInit() {

    if (this.highlightedFlat) {
      this.activeLevel = this.highlightedFlat.level;
    }
  }

  initFlatsByLevel() {

    this.flatsByLevel = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: []
    };

    _.forEach(this.allFlat, (flat: Flat) => {
      if (flat.latitude && flat.longitude) {
        this.flatsByLevel[flat.level].push(flat);
      }
    });

  }

}
