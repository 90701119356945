import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-section',
  templateUrl: './partner-section.component.html',
  styleUrls: ['./partner-section.component.scss']
})
export class PartnerSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openLink(url){
    let win = window.open(url, '_blank');
    win.focus();
  }


}
