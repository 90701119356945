import {ArticleTranslate} from './article-translate.model';
import {User} from "./user.model";
import {GalleryImage} from "./gallery-image.model";
import {ArticleTour} from './article-tour.model';
import {Page} from "./page.model";
import {Tag} from "./tag.model";

export class Article {
  id: number;
  oid: string;
  articleTrls: ArticleTranslate[] = [];
  imageUrl: string;
  youTubeVideoUrl: string;
  resourceUrl: string;

  createdAt: number;
  updatedAt: number;

  translatedTitle: string;
  translatedSummary: string;
  translatedBody: string;
  tags?: Tag[];
  pageType?: string;
  favorited: boolean;
  favoritesCount: number;
  author: User;
  highlightType?: string;
  topic?: string;
  publicationDate?: string;
  expirationDate?: string;
  galleryImages?: GalleryImage[];
  highlighted?: boolean;
  articleTours?: ArticleTour[];

  menuPage?: Page;

  latitude?: number;
  longitude?: number;

  relatedArticleId?: number;
  assigned?: boolean;
}
