import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/distinctUntilChanged';
import {Flat} from "../models/building/flat.model";
import {FlatArea} from "../models/building/flat-area.model";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import * as _ from 'underscore';

enum AREA {
  ELOTER = "előtér",
  WC = "wc",
  FURDO = "fürdő",
  HAZTARTARSI = "háztartási",
  KONYHA_ETKEZO = "konyha-étkező",
  NAPPALI = "nappali",
  SZOBA = "szoba",
  TERASZ = "terasz",
}

enum COVER {
  GRESLAP = "greslap",
  LAMINALT = "laminált parketta",
  FAGYALLO = "fagyálló burkolat"
}


@Injectable()
export class FlatService {

  public flatsSubject = new BehaviorSubject<Flat[]>([]);
  public flats = this.flatsSubject.asObservable().distinctUntilChanged();


  constructor() {
    let flats = [];
    for (let index = 1; index < 74; index++) {
      flats.push(this.getHorizontFlat(index));
    }
    this.flatsSubject.next(flats);
  }


  getAllFlat() {
    return this.flats;
  }


  getFlatFromProject(projectCode: string, flatId: number) {
    if (projectCode === "HORIZONT") {
      return this.getHorizontFlat(flatId)
    }
  }

  getHorizontFlat(flatId: number) {
    let flat = new Flat();
    flat.id = flatId;
    flat.project = "HORIZONT";

    let flatAreas = [];

    switch (flatId) {
      case 1:

        flat.name = "A/Fsz/8";
        flat.level = 0;
        flat.size = 60.06;
        flat.garden = 58.92;
        flat.coordinateX = 1111;
        flat.coordinateY = 585;
        flat.side = "FRONT";

        flat.latitude = 1030;
        flat.longitude = 642;
        flat.price = 32350000;
        flat.extra = '1 db klímát adunk ajándékba a lakáshoz!';
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 5.73, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.55, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 10.87, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.28, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 8.10, "fagyálló burkolat"));

        break;

      case 2:

        flat.name = "A/Fsz/1";
        flat.level = 0;
        flat.size = 46.28;
        flat.garden = 47.58;
        flat.coordinateX = 800;
        flat.coordinateY = 585;
        flat.side = "FRONT";

        flat.latitude = 747;
        flat.longitude = 640;
        flat.price = 23180000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.02, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.10, "fagyálló burkolat"));


        break;


      case 3:

        flat.name = "A/Fsz/2";
        flat.level = 0;
        flat.size = 46.37;
        flat.garden = 40.80;
        flat.coordinateX = 386;
        flat.coordinateY = 588;
        flat.side = "FRONT";


        flat.latitude = 607;
        flat.longitude = 640;
        flat.price = 23190000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.14, "fagyálló burkolat"));


        break;

      case 4:

        flat.name = "A/Fsz/3";
        flat.level = 0;
        flat.size = 71.05;
        flat.garden = 182.84;
        flat.coordinateX = 659;
        flat.coordinateY = 585;
        flat.side = "FRONT";


        flat.latitude = 484;
        flat.longitude = 637;
        flat.price = 34200000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 12.63, "fagyálló burkolat"));


        break;

      case 5:

        flat.name = "A/Fsz/4";
        flat.level = 0;
        flat.size = 71.05;
        flat.garden = 124.98;
        flat.coordinateX = 1515;
        flat.coordinateY = 654;
        flat.side = "BEHIND";


        flat.latitude = 481;
        flat.longitude = 405;
        flat.price = 32750000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 4.50, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 4.20, "fagyálló burkolat"));

        break;

      case 6:

        flat.name = "A/Fsz/5";
        flat.level = 0;
        flat.size = 45.72;
        flat.garden = 41.34;
        flat.coordinateX = 1329;
        flat.coordinateY = 653;
        flat.side = "BEHIND";


        flat.latitude = 627;
        flat.longitude = 402;
        flat.price = 22910000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 4.13, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.57, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.10, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 8.90, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.33, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.69, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));

        break;

      case 7:

        flat.name = "A/Fsz/6";
        flat.level = 0;
        flat.size = 46.35;
        flat.garden = 43.30;
        flat.coordinateX = 1153;
        flat.coordinateY = 657;
        flat.side = "BEHIND";


        flat.latitude = 754;
        flat.longitude = 405;
        flat.price = 22750000;

        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.27, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.10, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.82, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.65, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));


        break;

      case 8:

        flat.name = "A/Fsz/7";
        flat.level = 0;
        flat.size = 59.70;
        flat.garden = 56.85;
        flat.coordinateX = 850;
        flat.coordinateY = 656;
        flat.side = "BEHIND";


        flat.latitude = 1030;
        flat.longitude = 403;

        flat.price = 27500000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 5.77, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.20, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.95, "greslap"));
        flatAreas.push(new FlatArea(4, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 9.24, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "konyha-étkező", 10.15, "greslap"));
        flatAreas.push(new FlatArea(8, "nappali", 16.79, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 4.20, "fagyálló burkolat"));


        break;

      case 9:

        flat.name = "B/Fsz/1";
        flat.level = 0;
        flat.size = 45.95;
        flat.garden = 13.57;
        flat.coordinateX = 1382;
        flat.coordinateY = 581;
        flat.side = "FRONT";


        flat.latitude = 1308;
        flat.longitude = 640;

        flat.price = 22850000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.30, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.14, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.50, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 9.51, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.11, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.10, "fagyálló burkolat"));


        break;

      case 10:

        flat.name = "B/Fsz/2";
        flat.level = 0;
        flat.size = 43.286;
        flat.garden = 30.90;
        flat.coordinateX = 1244;
        flat.coordinateY = 585;
        flat.side = "FRONT";


        flat.latitude = 1174;
        flat.longitude = 640;

        flat.price = 21470000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.16, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.98, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.40, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.36, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));


        break;

      case 11:

        flat.name = "B/Fsz/3";
        flat.level = 0;
        flat.size = 48.47;
        flat.garden = 42.12;
        flat.coordinateX = 716;
        flat.coordinateY = 656;
        flat.side = "BEHIND";


        flat.latitude = 1174;
        flat.longitude = 403;

        flat.price = 23730000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.25, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.85, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 11.12, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.64, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));


        break;

      case 12:

        flat.name = "B/Fsz/4";
        flat.level = 0;
        flat.size = 45.80;
        flat.garden = 39.52;
        flat.coordinateX = 482;
        flat.coordinateY = 651;
        flat.side = "BEHIND";


        flat.latitude = 1306;
        flat.longitude = 406;

        flat.price = 22480000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.36, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 5.16, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.97, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.17, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.21, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));


        break;

      case 13:

        flat.name = "B/Fsz/5";
        flat.level = 0;
        flat.size = 50.32;
        flat.garden = 208.97;
        flat.coordinateX = 176;
        flat.coordinateY = 656;
        flat.side = "BEHIND";


        flat.latitude = 1618;
        flat.longitude = 390;

        flat.price = 26000000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.25, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.75, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.58, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.94, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 11.06, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "konyha-étkező", 8.25, "greslap"));
        flatAreas.push(new FlatArea(7, "nappali", 16.49, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "terasz", 4.20, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(9, "terasz", 3.54, "fagyálló burkolat"));

        break;

      case 14:

        flat.name = "B/Fsz/6";
        flat.level = 0;
        flat.size = 45.69;
        flat.garden = 61.79;
        flat.coordinateX = 1156;
        flat.coordinateY = 702;
        flat.side = "RIGHT";


        flat.latitude = 1630;
        flat.longitude = 510;

        flat.price = 22540000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.07, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.44, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.60, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));

        break;

      case 15:

        flat.name = "B/Fsz/7";
        flat.level = 0;
        flat.size = 45.01;
        flat.garden = 54.49;
        flat.coordinateX = 907;
        flat.coordinateY = 701;
        flat.side = "RIGHT";


        flat.latitude = 1630;
        flat.longitude = 643;

        flat.price = 22180000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.36, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.18, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.20, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.61, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.17, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 4.20, "fagyálló burkolat"));

        break;

      case 16:

        flat.name = "B/Fsz/8";
        flat.level = 0;
        flat.size = 102.35;
        flat.garden = 242.14;
        flat.coordinateX = 603;
        flat.coordinateY = 697;
        flat.side = "RIGHT";


        flat.latitude = 1633;
        flat.longitude = 829;

        flat.price = 49450000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.42, "greslap"));
        flatAreas.push(new FlatArea(2, "háztartási", 2.47, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.45, "greslap"));
        flatAreas.push(new FlatArea(4, "közlekedő", 11.45, "greslap"));
        flatAreas.push(new FlatArea(5, "fürdő", 6.26, "greslap"));
        flatAreas.push(new FlatArea(6, "wc", 1.53, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 11.00, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.80, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "fürdő", 3.12, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 9.88, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "szoba", 9.23, "laminált parketta"));
        flatAreas.push(new FlatArea(12, "konyha-étkező", 12.43, "greslap"));
        flatAreas.push(new FlatArea(13, "nappali", 18.31, "laminált parketta"));
        flatAreas.push(new FlatArea(14, "terasz", 4.69, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(15, "terasz", 12.63, "fagyálló burkolat"));

        break;

      case 17:

        flat.name = "A/I/9";
        flat.level = 1;
        flat.size = 60.06;
        flat.coordinateX = 1113;
        flat.coordinateY = 522;
        flat.side = "FRONT";


        flat.latitude = 1027;
        flat.longitude = 640;

        flat.price = 28710000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 5.73, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.55, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 10.87, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.28, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 9.00, "fagyálló burkolat"));

        break;

      case 18:

        flat.name = "A/I/1";
        flat.level = 1;
        flat.size = 46.28;
        flat.coordinateX = 813;
        flat.coordinateY = 521;
        flat.side = "FRONT";


        flat.latitude = 745;
        flat.longitude = 639;

        flat.price = 23190000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.02, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 19:

        flat.name = "A/I/2";
        flat.level = 1;
        flat.size = 46.37;
        flat.coordinateX = 669;
        flat.coordinateY = 516;
        flat.side = "FRONT";


        flat.latitude = 607;
        flat.longitude = 637;

        flat.price = 23540000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.49, "fagyálló burkolat"));

        break;

      case 20:

        flat.name = "A/I/3";
        flat.level = 1;
        flat.size = 71.05;
        flat.coordinateX = 383;
        flat.coordinateY = 514;
        flat.side = "FRONT";


        flat.latitude = 486;
        flat.longitude = 640;

        flat.price = 33410000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 13.22, "fagyálló burkolat"));

        break;

      case 21:

        flat.name = "A/I/4";
        flat.level = 1;
        flat.size = 71.05;
        flat.coordinateX = 1518;
        flat.coordinateY = 560;
        flat.side = "BEHIND";


        flat.latitude = 483;
        flat.longitude = 403;

        flat.price = 33030000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 4.80, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 22:

        flat.name = "A/I/5";
        flat.level = 1;
        flat.size = 45.72;
        flat.coordinateX = 1330;
        flat.coordinateY = 562;
        flat.side = "BEHIND";


        flat.latitude = 621;
        flat.longitude = 402;

        flat.price = 23160000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.13, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.57, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.10, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 8.90, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 10.69, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "nappali", 16.33, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 23:

        flat.name = "A/I/6";
        flat.level = 1;
        flat.size = 46.35;
        flat.coordinateX = 1159;
        flat.coordinateY = 566;
        flat.side = "BEHIND";


        flat.latitude = 754;
        flat.longitude = 403;

        flat.price = 23460000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.27, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.10, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.82, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.65, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 24:

        flat.name = "A/I/7";
        flat.level = 1;
        flat.size = 42.59;
        flat.coordinateX = 1051;
        flat.coordinateY = 566;
        flat.side = "BEHIND";


        flat.latitude = 943;
        flat.longitude = 421;

        flat.price = 20690000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.17, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.87, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 6.82, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.42, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 3.79, "fagyálló burkolat"));

        break;

      case 25:

        flat.name = "A/I/8";
        flat.level = 1;
        flat.size = 43.09;
        flat.coordinateX = 964;
        flat.coordinateY = 575;
        flat.side = "BEHIND";


        flat.latitude = 1030;
        flat.longitude = 403;

        flat.price = 21240000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.05, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.29, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.30, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.03, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.14, "fagyálló burkolat"));

        break;

      case 26:

        flat.name = "B/I/1";
        flat.level = 1;
        flat.size = 45.95;
        flat.coordinateX = 1384;
        flat.coordinateY = 521;
        flat.side = "FRONT";


        flat.latitude = 1306;
        flat.longitude = 639;

        flat.price = 23030000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.30, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.14, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.50, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 9.51, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.11, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 27:

        flat.name = "B/I/2";
        flat.level = 1;
        flat.size = 43.28;
        flat.coordinateX = 1242;
        flat.coordinateY = 523;
        flat.side = "FRONT";


        flat.latitude = 1174;
        flat.longitude = 640;

        flat.price = 22010000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.16, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.98, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.40, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.36, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.15, "fagyálló burkolat"));

        break;

      case 28:

        flat.name = "B/I/3";
        flat.level = 1;
        flat.size = 48.47;
        flat.coordinateX = 713;
        flat.coordinateY = 578;
        flat.side = "BEHIND";


        flat.latitude = 1173;
        flat.longitude = 403;

        flat.price = 23860000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.25, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.85, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 11.12, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.64, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.64, "fagyálló burkolat"));

        break;

      case 29:

        flat.name = "B/I/4";
        flat.level = 1;
        flat.size = 58.89;
        flat.coordinateX = 481;
        flat.coordinateY = 582;
        flat.side = "BEHIND";


        flat.latitude = 1308;
        flat.longitude = 402;

        flat.price = 27730000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.85, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 4.00, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.80, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.32, "greslap"));
        flatAreas.push(new FlatArea(5, "háztartási", 2.02, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 8.88, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 10.92, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "konyha-étkező", 8.02, "greslap"));
        flatAreas.push(new FlatArea(9, "nappali", 16.08, "laminált parketta"));
        flatAreas.push(new FlatArea(10, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 30:

        flat.name = "B/I/5";
        flat.level = 1;
        flat.size = 59.06;
        flat.coordinateX = 166;
        flat.coordinateY = 585;
        flat.side = "BEHIND";


        flat.latitude = 1620;
        flat.longitude = 391;

        flat.price = 28550000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.38, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.65, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.68, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 8.84, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.77, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(8, "nappali", 16.14, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 11.54, "fagyálló burkolat"));

        break;

      case 31:

        flat.name = "B/I/6";
        flat.level = 1;
        flat.size = 45.69;
        flat.coordinateX = 1160;
        flat.coordinateY = 590;
        flat.side = "RIGHT";


        flat.latitude = 1630;
        flat.longitude = 513;

        flat.price = 22520000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.07, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.44, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.60, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 32:

        flat.name = "B/I/7";
        flat.level = 1;
        flat.size = 45.01;
        flat.coordinateX = 904;
        flat.coordinateY = 586;
        flat.side = "RIGHT";

        flat.latitude = 1630;
        flat.longitude = 642;

        flat.price = 22210000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.36, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.18, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.20, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.61, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.17, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 33:

        flat.name = "B/I/8";
        flat.level = 1;
        flat.size = 102.35;
        flat.coordinateX = 603;
        flat.coordinateY = 581;
        flat.side = "RIGHT";

        flat.latitude = 1633;
        flat.longitude = 829;

        flat.price = 55382000;
        flat.extra = '1 db klímát adunk ajándékba a lakáshoz!';
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.42, "greslap"));
        flatAreas.push(new FlatArea(2, "háztartási", 2.47, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.45, "greslap"));
        flatAreas.push(new FlatArea(4, "közlekedő", 11.45, "greslap"));
        flatAreas.push(new FlatArea(5, "fürdő", 6.26, "greslap"));
        flatAreas.push(new FlatArea(6, "wc", 1.53, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 11.00, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.80, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "fürdő", 3.12, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 9.88, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "szoba", 9.23, "laminált parketta"));
        flatAreas.push(new FlatArea(12, "konyha-étkező", 12.43, "greslap"));
        flatAreas.push(new FlatArea(13, "nappali", 18.31, "laminált parketta"));
        flatAreas.push(new FlatArea(14, "terasz", 5.14, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(15, "terasz", 16.21, "fagyálló burkolat"));

        break;

      case 34:

        flat.name = "A/II/9";
        flat.level = 2;
        flat.size = 60.06;
        flat.coordinateX = 1131;
        flat.coordinateY = 460;
        flat.side = "FRONT";

        flat.latitude = 1027;
        flat.longitude = 639;

        flat.price = 29000000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 5.73, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.55, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 10.87, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.28, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 9.00, "fagyálló burkolat"));

        break;

      case 35:

        flat.name = "A/II/1";
        flat.level = 2;
        flat.size = 46.28;
        flat.coordinateX = 813;
        flat.coordinateY = 453;
        flat.side = "FRONT";

        flat.latitude = 745;
        flat.longitude = 639;

        flat.price = 23420000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.02, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 36:

        flat.name = "A/II/2";
        flat.level = 2;
        flat.size = 46.37;
        flat.coordinateX = 660;
        flat.coordinateY = 450;
        flat.side = "FRONT";

        flat.latitude = 607;
        flat.longitude = 640;


        flat.price = 23780000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.49, "fagyálló burkolat"));

        break;

      case 37:

        flat.name = "A/II/3";
        flat.level = 2;
        flat.size = 71.05;
        flat.coordinateX = 375;
        flat.coordinateY = 445;
        flat.side = "FRONT";

        flat.latitude = 487;
        flat.longitude = 639;


        flat.price = 33750000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 13.22, "fagyálló burkolat"));

        break;

      case 38:

        flat.name = "A/II/4";
        flat.level = 2;
        flat.size = 71.05;
        flat.coordinateX = 1520;
        flat.coordinateY = 465;
        flat.side = "BEHIND";

        flat.latitude = 484;
        flat.longitude = 405;


        flat.price = 33360000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 4.80, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 39:

        flat.name = "A/II/5";
        flat.level = 2;
        flat.size = 45.72;
        flat.coordinateX = 1350;
        flat.coordinateY = 472;
        flat.side = "BEHIND";

        flat.latitude = 624;
        flat.longitude = 403;

        flat.price = 23390000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 4.13, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.57, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.10, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 8.90, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 10.69, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "nappali", 16.33, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 40:

        flat.name = "A/II/6";
        flat.level = 2;
        flat.size = 46.35;
        flat.coordinateX = 1130;
        flat.coordinateY = 480;
        flat.side = "BEHIND";

        flat.latitude = 756;
        flat.longitude = 403;


        flat.price = 23690000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.27, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.10, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.82, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.65, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 41:

        flat.name = "A/II/7";
        flat.level = 2;
        flat.size = 42.59;
        flat.coordinateX = 1077;
        flat.coordinateY = 487;
        flat.side = "BEHIND";

        flat.latitude = 945;
        flat.longitude = 423;

        flat.price = 20900000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.17, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.87, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 6.82, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.42, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 3.79, "fagyálló burkolat"));

        break;

      case 42:

        flat.name = "A/II/8";
        flat.level = 2;
        flat.size = 43.09;
        flat.coordinateX = 930;
        flat.coordinateY = 493;
        flat.side = "BEHIND";

        flat.latitude = 1030;
        flat.longitude = 403;


        flat.price = 21450000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.05, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.29, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.30, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.03, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.14, "fagyálló burkolat"));

        break;

      case 43:

        flat.name = "B/II/1";
        flat.level = 2;
        flat.size = 45.95;
        flat.coordinateX = 1365;
        flat.coordinateY = 462;
        flat.side = "FRONT";

        flat.latitude = 1305;
        flat.longitude = 639;


        flat.price = 23260000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.30, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.14, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.50, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 9.51, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.11, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 44:

        flat.name = "B/II/2";
        flat.level = 2;
        flat.size = 43.28;
        flat.coordinateX = 1243;
        flat.coordinateY = 463;
        flat.side = "FRONT";

        flat.latitude = 1170;
        flat.longitude = 637;

        flat.price = 22230000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.16, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.98, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.40, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.36, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.15, "fagyálló burkolat"));

        break;

      case 45:

        flat.name = "B/II/3";
        flat.level = 2;
        flat.size = 48.47;
        flat.coordinateX = 721;
        flat.coordinateY = 497;
        flat.side = "BEHIND";

        flat.latitude = 1171;
        flat.longitude = 402;

        flat.price = 24090000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.25, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.85, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 11.12, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.64, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.64, "fagyálló burkolat"));

        break;

      case 46:

        flat.name = "B/II/4";
        flat.level = 2;
        flat.size = 58.89;
        flat.coordinateX = 473;
        flat.coordinateY = 509;
        flat.side = "BEHIND";

        flat.latitude = 1306;
        flat.longitude = 405;


        flat.price = 28010000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 2.85, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 4.00, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.80, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.32, "greslap"));
        flatAreas.push(new FlatArea(5, "háztartási", 2.02, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 8.88, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 10.92, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "konyha-étkező", 8.02, "greslap"));
        flatAreas.push(new FlatArea(9, "nappali", 16.08, "laminált parketta"));
        flatAreas.push(new FlatArea(10, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 47:

        flat.name = "B/II/5";
        flat.level = 2;
        flat.size = 59.06;
        flat.coordinateX = 178;
        flat.coordinateY = 515;
        flat.side = "BEHIND";

        flat.latitude = 1615;
        flat.longitude = 391;


        flat.price = 28830000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 4.38, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.65, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.68, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 8.84, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.77, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(8, "nappali", 16.14, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 11.54, "fagyálló burkolat"));

        break;

      case 48:

        flat.name = "B/II/6";
        flat.level = 2;
        flat.size = 45.69;

        flat.coordinateX = 1170;
        flat.coordinateY = 485;
        flat.side = "RIGHT";

        flat.latitude = 1627;
        flat.longitude = 511;

        flat.price = 22750000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.07, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.44, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.60, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 49:

        flat.name = "B/II/7";
        flat.level = 2;
        flat.size = 45.01;

        flat.coordinateX = 913;
        flat.coordinateY = 482;
        flat.side = "RIGHT";

        flat.latitude = 1627;
        flat.longitude = 640;


        flat.price = 22430000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.36, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.18, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.20, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.61, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.17, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 50:

        flat.name = "B/II/8";
        flat.level = 2;
        flat.size = 102.35;

        flat.coordinateX = 610;
        flat.coordinateY = 467;
        flat.side = "RIGHT";


        flat.latitude = 1635;
        flat.longitude = 828;

        flat.price = 55947000;
        flat.extra = '1 db klímát adunk ajándékba a lakáshoz!';
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.42, "greslap"));
        flatAreas.push(new FlatArea(2, "háztartási", 2.47, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.45, "greslap"));
        flatAreas.push(new FlatArea(4, "közlekedő", 11.45, "greslap"));
        flatAreas.push(new FlatArea(5, "fürdő", 6.26, "greslap"));
        flatAreas.push(new FlatArea(6, "wc", 1.53, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 11.00, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.80, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "fürdő", 3.12, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 9.88, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "szoba", 9.23, "laminált parketta"));
        flatAreas.push(new FlatArea(12, "konyha-étkező", 12.43, "greslap"));
        flatAreas.push(new FlatArea(13, "nappali", 18.31, "laminált parketta"));
        flatAreas.push(new FlatArea(14, "terasz", 5.14, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(15, "terasz", 16.21, "fagyálló burkolat"));

        break;

      case 51:

        flat.name = "A/III/9";
        flat.level = 3;
        flat.size = 60.06;

        flat.coordinateX = 1120;
        flat.coordinateY = 400;
        flat.side = "FRONT";


        flat.latitude = 1026;
        flat.longitude = 639;

        flat.price = 29290000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 5.73, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.55, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 10.87, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.28, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 9.00, "fagyálló burkolat"));

        break;

      case 52:

        flat.name = "A/III/1";
        flat.level = 3;
        flat.size = 46.28;

        flat.coordinateX = 814;
        flat.coordinateY = 391;
        flat.side = "FRONT";

        flat.latitude = 745;
        flat.longitude = 640;


        flat.price = 23650000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.02, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 53:

        flat.name = "A/III/2";
        flat.level = 3;
        flat.size = 46.37;

        flat.coordinateX = 677;
        flat.coordinateY = 381;
        flat.side = "FRONT";

        flat.latitude = 607;
        flat.longitude = 640;

        flat.price = 24010000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.93, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.09, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.81, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.95, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.49, "fagyálló burkolat"));

        break;

      case 54:

        flat.name = "A/III/3";
        flat.level = 3;
        flat.size = 71.05;

        flat.coordinateX = 375;
        flat.coordinateY = 375;
        flat.side = "FRONT";

        flat.latitude = 487;
        flat.longitude = 642;

        flat.price = 34080000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 13.22, "fagyálló burkolat"));

        break;

      case 55:

        flat.name = "A/III/4";
        flat.level = 3;
        flat.size = 71.05;

        flat.coordinateX = 1520;
        flat.coordinateY = 376;
        flat.side = "BEHIND";

        flat.latitude = 484;
        flat.longitude = 403;

        flat.price = 33690000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.82, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.90, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.34, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.25, "greslap"));
        flatAreas.push(new FlatArea(5, "wc", 1.50, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 10.91, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 9.75, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.31, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.07, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 4.80, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 56:

        flat.name = "A/III/5";
        flat.level = 3;
        flat.size = 45.72;

        flat.coordinateX = 1333;
        flat.coordinateY = 382;
        flat.side = "BEHIND";

        flat.latitude = 621;
        flat.longitude = 402;

        flat.price = 23630000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.13, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.57, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.10, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 8.90, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 10.69, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "nappali", 16.33, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 57:

        flat.name = "A/III/6";
        flat.level = 3;
        flat.size = 46.35;

        flat.coordinateX = 1125;
        flat.coordinateY = 400;
        flat.side = "BEHIND";

        flat.latitude = 756;
        flat.longitude = 405;


        flat.price = 23930000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.48, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.27, "greslap"));
        flatAreas.push(new FlatArea(4, "konyha-étkező", 9.10, "greslap"));
        flatAreas.push(new FlatArea(5, "nappali", 16.82, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.65, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 58:

        flat.name = "A/III/7";
        // What the heck?
        flat.level = 3;
        flat.size = 42.59;


        flat.latitude = 943;
        flat.longitude = 423;

        flat.price = 21100000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.17, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.96, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.87, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 6.82, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.42, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 3.79, "fagyálló burkolat"));

        break;

      case 59:

        flat.name = "A/III/8";
        flat.level = 3;
        flat.size = 43.09;

        flat.coordinateX = 862;
        flat.coordinateY = 416;
        flat.side = "BEHIND";

        flat.latitude = 1027;
        flat.longitude = 403;


        flat.price = 21660000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.03, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.05, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.29, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.30, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.03, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.14, "fagyálló burkolat"));

        break;

      case 60:

        flat.name = "B/III/1";
        flat.level = 3;
        flat.size = 45.95;


        flat.coordinateX = 1380;
        flat.coordinateY = 406;
        flat.side = "FRONT";

        flat.latitude = 1308;
        flat.longitude = 640;

        flat.price = 23490000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.30, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.39, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.14, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.50, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 9.51, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.11, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 6.15, "fagyálló burkolat"));

        break;

      case 61:

        flat.name = "B/III/2";
        flat.level = 3;
        flat.size = 43.28;

        flat.coordinateX = 1245;
        flat.coordinateY = 403;
        flat.side = "FRONT";

        flat.latitude = 1174;
        flat.longitude = 637;


        flat.price = 22450000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.16, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.98, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.40, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.36, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 7.15, "fagyálló burkolat"));

        break;

      case 62:

        flat.name = "B/III/3";
        flat.level = 3;
        flat.size = 48.47;

        flat.coordinateX = 714;
        flat.coordinateY = 425;
        flat.side = "BEHIND";

        flat.latitude = 1173;
        flat.longitude = 403;

        flat.price = 24330000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.25, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 3.85, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.26, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 11.12, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.64, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.64, "fagyálló burkolat"));

        break;

      case 63:

        flat.name = "B/III/4";
        flat.level = 3;
        flat.size = 58.89;

        flat.coordinateX = 455;
        flat.coordinateY = 437;
        flat.side = "BEHIND";

        flat.latitude = 1306;
        flat.longitude = 400;

        flat.price = 28280000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 2.85, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 4.00, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.80, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.32, "greslap"));
        flatAreas.push(new FlatArea(5, "háztartási", 2.02, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 8.88, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 10.92, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "konyha-étkező", 8.02, "greslap"));
        flatAreas.push(new FlatArea(9, "nappali", 16.08, "laminált parketta"));
        flatAreas.push(new FlatArea(10, "terasz", 8.16, "fagyálló burkolat"));

        break;

      case 64:

        flat.name = "B/III/5";
        flat.level = 3;
        flat.size = 59.06;

        flat.coordinateX = 175;
        flat.coordinateY = 450;
        flat.side = "BEHIND";

        flat.latitude = 1617;
        flat.longitude = 390;

        flat.price = 29120000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 4.38, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 3.65, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.68, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 8.84, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.77, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "konyha-étkező", 9.11, "greslap"));
        flatAreas.push(new FlatArea(8, "nappali", 16.14, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "terasz", 11.54, "fagyálló burkolat"));

        break;

      case 65:

        flat.name = "B/III/6";
        flat.level = 3;
        flat.size = 45.69;

        flat.coordinateX = 1170;
        flat.coordinateY = 382;
        flat.side = "RIGHT";

        flat.latitude = 1629;
        flat.longitude = 513;

        flat.price = 22970000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 4.07, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.03, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.44, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 8.20, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.60, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 66:

        flat.name = "B/III/7";
        flat.level = 3;
        flat.size = 45.01;

        flat.coordinateX = 915;
        flat.coordinateY = 368;
        flat.side = "RIGHT";

        flat.latitude = 1627;
        flat.longitude = 643;

        flat.price = 26260000;
        flat.soldOut = true;
        flat.extra = '1 db klímát adunk ajándékba a lakáshoz!';

        flatAreas.push(new FlatArea(1, "előtér", 4.36, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.49, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.18, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.20, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.61, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 17.17, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 5.47, "fagyálló burkolat"));

        break;

      case 67:

        flat.name = "B/III/8";
        flat.level = 3;
        flat.size = 102.35;

        flat.coordinateX = 615;
        flat.coordinateY = 350;
        flat.side = "RIGHT";

        flat.latitude = 1632;
        flat.longitude = 828;

        flat.price = 49600000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.42, "greslap"));
        flatAreas.push(new FlatArea(2, "háztartási", 2.47, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.45, "greslap"));
        flatAreas.push(new FlatArea(4, "közlekedő", 11.45, "greslap"));
        flatAreas.push(new FlatArea(5, "fürdő", 6.26, "greslap"));
        flatAreas.push(new FlatArea(6, "wc", 1.53, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 11.00, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.80, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "fürdő", 3.12, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 9.88, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "szoba", 9.23, "laminált parketta"));
        flatAreas.push(new FlatArea(12, "konyha-étkező", 12.43, "greslap"));
        flatAreas.push(new FlatArea(13, "nappali", 18.31, "laminált parketta"));
        flatAreas.push(new FlatArea(14, "terasz", 5.14, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(15, "terasz", 16.21, "fagyálló burkolat"));

        break;

      case 68:

        flat.name = "A/IV/1";
        flat.level = 4;
        flat.size = 169.31;

        flat.coordinateX = 525;
        flat.coordinateY = 309;
        flat.side = "FRONT";

        flat.latitude = 618;
        flat.longitude = 466;


        flat.price = 109390000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 9.44, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 12.75, "greslap"));
        flatAreas.push(new FlatArea(3, "szoba", 15.19, "laminált parketta"));
        flatAreas.push(new FlatArea(4, "szoba", 14.62, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "wc", 1.70, "greslap"));
        flatAreas.push(new FlatArea(6, "fürdő", 10.43, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 12.61, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "wc", 2.00, "greslap"));
        flatAreas.push(new FlatArea(9, "háztartási", 3.30, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 18.19, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "fürdő", 6.25, "greslap"));
        flatAreas.push(new FlatArea(12, "konyha-étkező", 30.71, "greslap"));
        flatAreas.push(new FlatArea(13, "nappali", 32.12, "laminált parketta"));
        flatAreas.push(new FlatArea(14, "terasz", 142.92, "fagyálló burkolat"));


        break;

      case 69:

        flat.name = "A/IV/2";
        flat.level = 4;
        flat.size = 46.45;

        flat.coordinateX = 1180;
        flat.coordinateY = 317;
        flat.side = "BEHIND";

        flat.latitude = 735;
        flat.longitude = 450;

        flat.price = 76140000;
        flat.soldOut = true;


        flatAreas.push(new FlatArea(1, "előtér", 5.55, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.98, "greslap"));
        flatAreas.push(new FlatArea(3, "fürdő", 4.65, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 10.80, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "konyha-étkező", 7.41, "greslap"));
        flatAreas.push(new FlatArea(6, "nappali", 16.06, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "terasz", 11.77, "fagyálló burkolat"));

        break;

      case 70:

        flat.name = "A/IV/3";
        flat.level = 4;
        flat.size = 85.37;

        flat.coordinateX = 1086;
        flat.coordinateY = 333;
        flat.side = "FRONT";

        flat.latitude = 1039;
        flat.longitude = 456;

        flat.price = 45360000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 6.61, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 2.06, "greslap"));
        flatAreas.push(new FlatArea(3, "közlekedő", 4.05, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.80, "greslap"));
        flatAreas.push(new FlatArea(5, "szoba", 9.94, "lam. parketta"));
        flatAreas.push(new FlatArea(6, "szoba", 10.87, "lam. parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 10.60, "lam. parketta"));
        flatAreas.push(new FlatArea(8, "konyha-étkező", 15.59, "greslap"));
        flatAreas.push(new FlatArea(9, "nappali", 20.85, "laminált parketta"));
        flatAreas.push(new FlatArea(10, "terasz", 22.90, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(11, "terasz", 20.15, "fagyálló burkolat"));


        break;

      case 71:

        flat.name = "B/IV/1";
        flat.level = 4;
        flat.size = 111.17;

        flat.coordinateX = 1333;
        flat.coordinateY = 341;
        flat.side = "FRONT";

        flat.latitude = 1188;
        flat.longitude = 591;

        flat.price = 67635000;
        flat.extra = '2 db klímát adunk ajándékba a lakáshoz!';
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 11.65, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 1.67, "greslap"));
        flatAreas.push(new FlatArea(3, "szoba", 12.80, "laminált parketta"));
        flatAreas.push(new FlatArea(4, "közlekedő", 3.50, "greslap"));
        flatAreas.push(new FlatArea(5, "háztartási", 2.86, "greslap"));
        flatAreas.push(new FlatArea(6, "zuhanyzó", 3.61, "greslap"));
        flatAreas.push(new FlatArea(7, "szoba", 11.13, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "wc", 1.43, "greslap"));
        flatAreas.push(new FlatArea(9, "közlekedő", 3.78, "greslap"));
        flatAreas.push(new FlatArea(10, "szoba", 9.86, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "fürdő", 6.38, "greslap"));
        flatAreas.push(new FlatArea(12, "szoba", 10.80, "laminált parketta"));
        flatAreas.push(new FlatArea(13, "konyha-étkező", 13.09, "greslap"));
        flatAreas.push(new FlatArea(14, "nappali", 18.61, "laminált parketta"));
        flatAreas.push(new FlatArea(15, "terasz", 21.25, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(16, "terasz", 26.95, "fagyálló burk"));

        break;

      case 72:

        flat.name = "B/IV/2";
        flat.level = 4;
        flat.size = 82.61;

        flat.coordinateX = 1132;
        flat.coordinateY = 275;
        flat.side = "RIGHT";

        flat.latitude = 1354;
        flat.longitude = 457;

        flat.soldOut = true;
        flat.price = 46330000;

        flatAreas.push(new FlatArea(1, "előtér+gardrób", 8.23, "greslap"));
        flatAreas.push(new FlatArea(2, "wc", 2.55, "greslap"));
        flatAreas.push(new FlatArea(3, "háztartási", 2.80, "greslap"));
        flatAreas.push(new FlatArea(4, "fürdő", 4.51, "greslap"));
        flatAreas.push(new FlatArea(5, "közlekedő", 3.52, "greslap"));
        flatAreas.push(new FlatArea(6, "szoba", 11.26, "laminált parketta"));
        flatAreas.push(new FlatArea(7, "szoba", 8.96, "laminált parketta"));
        flatAreas.push(new FlatArea(8, "szoba", 11.62, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 10.14, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 19.02, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 12.66, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 23.90, "fagyálló burkolat"));

        break;


      case 73:

        flat.name = "B/IV/3";
        flat.level = 4;
        flat.size = 81.95;

        flat.coordinateX = 1632;
        flat.coordinateY = 323;
        flat.side = "FRONT";

        flat.latitude = 1578;
        flat.longitude = 684;

        flat.price = 52050000;
        flat.soldOut = true;

        flatAreas.push(new FlatArea(1, "előtér", 3.90, "greslap"));
        flatAreas.push(new FlatArea(2, "közlekedő", 5.21, "greslap"));
        flatAreas.push(new FlatArea(3, "wc", 1.35, "greslap"));
        flatAreas.push(new FlatArea(4, "szoba", 9.60, "laminált parketta"));
        flatAreas.push(new FlatArea(5, "szoba", 11.90, "laminált parketta"));
        flatAreas.push(new FlatArea(6, "fürdő", 6.04, "greslap"));
        flatAreas.push(new FlatArea(7, "háztartási", 3.19, "greslap"));
        flatAreas.push(new FlatArea(8, "szoba", 8.84, "laminált parketta"));
        flatAreas.push(new FlatArea(9, "konyha-étkező", 15.00, "greslap"));
        flatAreas.push(new FlatArea(10, "nappali", 16.92, "laminált parketta"));
        flatAreas.push(new FlatArea(11, "terasz", 23.37, "fagyálló burkolat"));
        flatAreas.push(new FlatArea(12, "terasz", 37.16, "fagyálló burkolat"));

        break;


      // case 74: // SAME AS 75-76, LEVEL IS DIFFERENT
      //
      //
      //   flat.level = 1;
      //   flat.size = 93.47;
      //   flat.concat = true;
      //   flat.concatIds = [18, 19];
      //   flat.name = "A/I/1 + A/I/2";
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_a.jpg';
      //   flat.price = 39460000;
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 6.25, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 2.09, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 6.89, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.36, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 15.98, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 19.98, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.49, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;
      // case 75: // SAME AS 74-76, LEVEL IS DIFFERENT
      //
      //
      //   flat.level = 2;
      //   flat.size = 93.47;
      //   flat.concat = true;
      //   flat.concatIds = [35, 36];
      //   flat.name = "A/II/1 + A/II/2";
      //   flat.price = 39850000;
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_a.jpg';
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 6.25, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 2.09, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 6.89, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.36, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 15.98, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 19.98, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.49, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;
      // case 76: // SAME AS 74-75, LEVEL IS DIFFERENT
      //
      //
      //   flat.level = 3;
      //   flat.size = 93.47;
      //   flat.concat = true;
      //   flat.concatIds = [52, 53];
      //   flat.name = "A/III/1 + A/III/2";
      //   flat.price = 40240000;
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_a.jpg';
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 6.25, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 2.09, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 6.89, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.36, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 15.98, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 19.98, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.49, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;
      //
      //
      // case 77: // SAME AS 78-79, LEVEL IS DIFFERENT
      //
      //   flat.name = "";
      //   flat.level = 1;
      //   flat.size = 90.33;
      //   flat.concat = true;
      //   flat.concatIds = [26, 27];
      //   flat.name = "B/I/1 + B/I/2";
      //   flat.price = 38020000;
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_b.jpg';
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 5.95, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 1.89, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 7.06, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.45, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 14.74, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 18.32, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.15, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;
      //
      // case 78: // SAME AS 77-79, LEVEL IS DIFFERENT
      //
      //   flat.level = 2;
      //   flat.size = 90.33;
      //   flat.concat = true;
      //   flat.concatIds = [43, 44];
      //   flat.name = "B/II/1 + B/II/2";
      //   flat.price = 38410000;
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_b.jpg';
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 5.95, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 1.89, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 7.06, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.45, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 14.74, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 18.32, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.15, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;
      //
      // case 79: // SAME AS 77-78, LEVEL IS DIFFERENT
      //
      //   flat.level = 3;
      //   flat.size = 90.33;
      //   flat.concat = true;
      //   flat.concatIds = [60, 61];
      //   flat.name = "B/III/1 + B/III/2";
      //   flat.price = 38790000;
      //   flat.concatImageUrl = '/assets/images/projects/horizont/flats/flat_position/concat_b.jpg';
      //
      //   flatAreas.push(new FlatArea(1, "előtér", 3.80, "greslap"));
      //   flatAreas.push(new FlatArea(2, "közlekedő", 5.95, "greslap"));
      //   flatAreas.push(new FlatArea(3, "wc", 2.33, "greslap"));
      //   flatAreas.push(new FlatArea(4, "háztartási", 2.73, "greslap"));
      //   flatAreas.push(new FlatArea(5, "wc", 1.89, "greslap"));
      //   flatAreas.push(new FlatArea(6, "fürdő", 7.06, "greslap"));
      //   flatAreas.push(new FlatArea(7, "szoba", 12.45, "laminált parketta"));
      //   flatAreas.push(new FlatArea(8, "szoba", 10.44, "laminált parketta"));
      //   flatAreas.push(new FlatArea(9, "szoba", 10.62, "laminált parketta"));
      //   flatAreas.push(new FlatArea(10, "konyha-étkező", 14.74, "greslap"));
      //   flatAreas.push(new FlatArea(11, "nappali", 18.32, "laminált parketta"));
      //   flatAreas.push(new FlatArea(12, "terasz", 7.15, "fagyálló burkolat"));
      //   flatAreas.push(new FlatArea(13, "terasz", 6.15, "fagyálló burkolat"));
      //
      //   break;



    }

    flat.flatAreas = flatAreas;
    flat.numberOfRooms = this.setNumberOfRooms(flatAreas);
    return flat;

  }

  setNumberOfRooms(flatAreas) {

    let numberOfRooms = 0;

    _.forEach(flatAreas, (flatArea: FlatArea) => {
      if (flatArea.name === "szoba") {
        numberOfRooms = numberOfRooms + 1;
      }
    });
    return numberOfRooms

  }


  setHorizontFlatName(flatId) {

    if (flatId < 10) {
      return "L0" + flatId;
    } else {
      return "L" + flatId;
    }


  }


}
