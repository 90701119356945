import {User} from "./user.model";
import {Post} from "./post.model";

export class Topic {
  id?: number;
  subject?: string;
  description?: string;
  author?: User;
  createdDate: string;
  postCount?: number;
  posts: Post[];
  languageCode: string;
}
