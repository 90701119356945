import {Component, ElementRef, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AgmMap, MapsAPILoader} from '@agm/core';
import * as _ from 'underscore';
import {MAP_CENTER_LAT_LNG} from '../../../../settings';
import {FormControl} from "@angular/forms";

import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MapService} from "../../services/map.service";
import {ProjectItem} from "../../models/building/project-item.model";

@Component({
  selector: 'app-projects-map',
  templateUrl: './projects-map.component.html',
  styleUrls: ['./projects-map.component.scss']
})
export class ProjectsMapComponent implements OnInit {

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @Input() disableScroll: boolean;
  @Input() focusOn: string;
  projects: ProjectItem[] = [];

  zoom = 11;
  mapCenterLatitude = 47.677423;
  mapCenterLongitude = 17.649759;
  bounds: any;
  map: any;
  customGoogleMapStyle: any;

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private translate: TranslateService,
              private router: Router) {

    this.customGoogleMapStyle = MapService.getCustomMapStyle();

  }

  ngOnInit() {


    if(!this.focusOn || this.focusOn === 'HORIZONT'){
      let projectItem1 = new ProjectItem();
      projectItem1.name = "Horizont Lakópark";
      projectItem1.latitude = 47.6567449;
      projectItem1.longitude = 17.661649;
      projectItem1.code = "horizont";
      this.projects.push(projectItem1);
    }

    if(!this.focusOn || this.focusOn === 'SZITAS1144'){
      let projectItem2 = new ProjectItem();
      projectItem2.name = "Szitás domb [1144]";
      projectItem2.latitude = 47.720755;
      projectItem2.longitude = 17.638655;
      this.projects.push(projectItem2);
    }

    if(!this.focusOn || this.focusOn === 'SZITAS12511'){
      let projectItem3 = new ProjectItem();
      projectItem3.name = "Szitás domb [1251/1]";
      projectItem3.latitude = 47.719916;
      projectItem3.longitude = 17.639515;
      this.projects.push(projectItem3);
    }

    if(!this.focusOn || this.focusOn === 'SZITAS12513'){

      let projectItem4 = new ProjectItem();
      projectItem4.name = "Szitás domb [1251/3]";
      projectItem4.latitude = 47.720170;
      projectItem4.longitude = 17.639279;
      this.projects.push(projectItem4);
    }

    if(this.focusOn){
      this.mapCenterLatitude = this.projects[0].latitude;
      this.mapCenterLongitude = this.projects[0].longitude;
      this.zoom = 15;
    }

  }


  ngOnChanges(changes: SimpleChanges) {

  }

  onMapReady(map) {
    this.map = map;
    this.map.styles = this.customGoogleMapStyle;
    this.setBounds();

  }

  setBounds() {

    // this.bounds = new google.maps.LatLngBounds();
    //
    // _.forEach(this.projects, (projectItem: ProjectItem) => {
    //   debugger;
    //   if (projectItem.longitude && projectItem.latitude) {
    //     const latLng = new google.maps.LatLng(projectItem.longitude, projectItem.latitude);
    //     this.bounds.extend(latLng);
    //   }
    // });
    //
    // this.map.fitBounds(this.bounds);
    // this.map.panToBounds(this.bounds);
  }


  openProjectPage(project: ProjectItem) {
    this.router.navigateByUrl('/home/project/' + project.code);
  }

}
