import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
@Component({
  selector: 'app-szitas12511',
  templateUrl: './szitas12511.component.html',
  styleUrls: ['./szitas12511.component.scss']
})
export class Szitas12511Component implements OnInit {

  constructor(private location: Location) {

  }
  ngOnInit() {
  }

  backToPrevPage() {
    this.location.back();
  }

}
