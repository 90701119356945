export class Page {
  id?: number;
  userId?: number;
  code?: string;
  url?: string;
  title?: string;
  text?: string;
  dateCreated?: string;
  showTitle?: boolean;
}
