import {FlatArea} from "./flat-area.model";

export class Flat {

  id?: number;
  name?: string;
  size?: number;
  sizeDescription?: string;
  price?: number;
  description?: string;
  images?: string[];
  coverImageUrl?: string;
  level?: number;
  flatAreas?: FlatArea[];
  numberOfRooms?: number;
  project?: string;
  coordinateX?: number;
  coordinateY?: number;
  latitude?: number;
  longitude?: number;
  side?: string;
  imageUrlForCoordinate: string;
  concat?: boolean;
  concatIds?: number[];
  concatImageUrl?: string;
  extra?: string;
  garden?: number;
  soldOut?: boolean;
  reserved?: boolean;
}
