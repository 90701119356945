import {UserFeature} from './user-feature.model';
import {UserRoute} from './user-route.model';

export class UserRole {
  id?: number;
  name: string;
  canWrite?: boolean;

  routes: UserRoute[];
  features: UserFeature[];

  constructor(name?) {
    this.name = name;
  }
}
