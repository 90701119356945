import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery";

@Component({
  selector: 'app-indoor-images',
  templateUrl: './indoor-images.component.html',
  styleUrls: ['./indoor-images.component.scss']
})
export class IndoorImagesComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  @ViewChild('ngxGalleryRef') ngxGalleryRef: NgxGalleryComponent;

  constructor() { }

  ngOnInit() {
    this.initGallery();
  }

  initGallery() {

    this.galleryOptions = [
      {
        image: false,
        thumbnails: false,
        width: "0px",
        height: "0px",
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowPrevIcon: "icon angle left",
        arrowNextIcon: "icon angle right",
        previewSwipe: true,
        thumbnailsSwipe: true
      }
    ];

    this.galleryImages = [];

    let image1Url = "/assets/images/projects/horizont/indoor/indoor_1.jpg";
    let image2Url = "/assets/images/projects/horizont/indoor/indoor_2.jpg";
    let image3Url = "/assets/images/projects/horizont/indoor/indoor_3.jpg";

    this.galleryImages.push({
      small: image1Url,
      medium: image1Url,
      big: image1Url
    });

    this.galleryImages.push({
      small: image2Url,
      medium: image2Url,
      big: image2Url
    });

    this.galleryImages.push({
      small: image3Url,
      medium: image3Url,
      big: image3Url
    });
  }

  openNgxGallery(index): void {
    this.ngxGalleryRef.openPreview(index);
  }


}
