import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

import {User} from "../../models/user.model";
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  currentUser: User;
  isSidebarOpen: boolean = false;
  isScrolledDown: Boolean = false;

  constructor(private router: Router,
              private translateService: TranslateService,
              private stateService: StateService) {
  }

  ngOnInit() {
  }

  navigateToHomePage() {
    this.router.navigateByUrl('/home/main');
  }

  navigateToContact() {
    this.router.navigateByUrl('/home/contact');
  }

  openMail(mail) {
    window.location.href = 'mailto:' + mail;
  }

  openPhone(phone) {
    window.open('tel:'+ phone+'');
  }





}
