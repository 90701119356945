import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {addDays, addHours, endOfDay, endOfMonth, isSameDay, isSameMonth, startOfDay, subDays} from 'date-fns';
import {Subject} from 'rxjs/Subject';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, DAYS_OF_WEEK} from 'angular-calendar';
import {Event} from '../../models/event.model';
import * as _ from 'underscore';
import * as moment from 'moment';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  uiBlue: {
    primary: '#2185d0',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  silverPurple: {
    primary: '#a3acd8',
    secondary: '#D1E8FF'
  }
};

@Component({
  selector: 'app-event-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit, OnChanges {

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() events: Event[];

  view: string = 'month';

  viewDate: Date = new Date();

  locale: string;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  calendarEvents: CalendarEvent[];

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = true;

  constructor(private translate: TranslateService,
              private router: Router) {
    this.locale = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.locale = this.translate.currentLang;
      this.calendarEvents = this.prepareEventDataForCalendar(this.events);
      this.refresh.next();
    });


  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.events && changes.events.currentValue) {
      this.calendarEvents = this.prepareEventDataForCalendar(changes.events.currentValue);
      this.refresh.next();
    }
  }



  ngOnInit(): void {
    this.calendarEvents = this.prepareEventDataForCalendar(this.events);
  }

  prepareEventDataForCalendar(events) {
    const calendarEvents: CalendarEvent[] = [];
    _.forEach(events, (event: Event) => {
      calendarEvents.push({
        start: moment(event.eventStartDate).toDate(),
        end: moment(event.eventEndDate).toDate(),
        title: event["title"],
        color: this.colorByType(event.eventType),
        actions: this.actions,
        id: event.id
      });
    });

    return calendarEvents;
  }

  colorByType(type) {
    if (type === 'MARKET' || type === 'COURSE') {
      return colors.uiBlue;
    } else {
      return colors.silverPurple;
    }
  }


  // calendarEvents: CalendarEvent[] = [
  //   {
  //     start: subDays(startOfDay(new Date()), 1),
  //     end: addDays(new Date(), 1),
  //     title: 'A 3 day event',
  //     color: colors.red,
  //     actions: this.actions
  //   },
  //   {
  //     start: startOfDay(new Date()),
  //     title: 'An event with no end date',
  //     color: colors.yellow,
  //     actions: this.actions
  //   },
  //   {
  //     start: subDays(endOfMonth(new Date()), 3),
  //     end: addDays(endOfMonth(new Date()), 3),
  //     title: 'A long event that spans 2 months',
  //     color: colors.blue
  //   },
  //   {
  //     start: addHours(startOfDay(new Date()), 2),
  //     end: new Date(),
  //     title: 'A draggable and resizable event',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true
  //     },
  //     draggable: true
  //   }
  // ];

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.router.navigateByUrl('/event/' + event.id);
  }

  addEvent(): void {
    this.calendarEvents.push({
      title: 'New event',
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
      color: colors.red,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      }
    });
    this.refresh.next();
  }

}
