export class Mass {
  // id?: number;
  winterId?: number;
  summerId?: number;
  church_id?: number;
  day?: string;
  winterTime?: string;
  summerTime?: string;
  winterComment?: string;
  summerComment?: string;
}
