import {Component, OnInit} from '@angular/core';
import {StateService} from "../../services/state.service";

@Component({
  selector: 'app-navigation-loader',
  templateUrl: './navigation-loader.component.html',
  styleUrls: ['./navigation-loader.component.scss']
})
export class NavigationLoaderComponent implements OnInit {


  showNavigationLoader: Boolean = false;

  constructor(private stateService: StateService) {

    this.stateService.navigationLoaderState.subscribe((state) => {
      this.showNavigationLoader = state;
    })

  }

  ngOnInit() {
  }

}
